<div class="outer-container">
    <div
      class="center-container"
      style="height: 110vh; width: 35vw; padding: 10px;padding-top: 30px;"
    >
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >
          <h4>Edit  Facility</h4>
        </div>
        <div>
          <form style="width: 28vw;" [formGroup]="editFacilityForm">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Country</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="selectedCountry"
              [value]="facilityRecord?.country"
              name="country" readonly/>
            </div>
  
            <div class="form-group">
              <label for="exampleInput1">Local Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter Local Code"
                formControlName="local_Code"
                [value]="facilityRecord?.localCode"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Province</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="selectedProvince"
              [value]="facilityRecord?.province"
              name="province" readonly/>
            </div>
            <div class="form-group">
                <label for="exampleFormControlSelect1">District</label>
                <select
                  class="form-control form-control-lg"
                  id="exampleFormControlSelect1"
                  (change)="onDistrictChange($event)"
                  formControlName="selectedDistrict"
                >
                <option [value]="facilityRecord?.districtId">{{ facilityRecord?.district }}</option>
                <option *ngFor="let district of districts" [value]="district.id">
                  {{ district.name }}
                </option>
                </select>
              </div>
            <div class="form-group">
              <label for="exampleInput1">Sub District</label>
              <select
                  class="form-control form-control-lg"
                  id="exampleFormControlSelect1"
                  (change)="onSubDistrictChange($event)"
                  formControlName="selectedSubDistrict"
                >
                <option [value]="facilityRecord?.tehsilId">{{ facilityRecord?.tehsil }}</option>
                <option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.id">
                  {{ subdistrict.name }}
                </option>
                </select>
            </div>
            <div style=" display: flex;gap:5px;">
                <div class="form-group">
                    <label for="exampleInput1">Union Council</label>
                    <select
                    class="form-control form-control-lg"
                    id="exampleFormControlSelect1"
                    (change)="onUCChange($event)"
                    formControlName="selectedUC"
                  >
                  <option [value]="facilityRecord?.ucId">{{ facilityRecord?.uc }}</option>
                  <option *ngFor="let uc of unioncouncil" [value]="uc.id">
                    {{ uc.name }}
                  </option>
                  </select>
                  </div>
                  <div class="form-group">
                    <label for="exampleInput1">Facility</label>
                    <input
                    type="text"
                    class="form-control form-control-lg"
                     id="facility"
                     placeholder="Enter New facility"
                     formControlName="facility_name"
                      name="facility"
                     />
                  </div>
            </div>
          
            <div style="display: flex; justify-content: space-between">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  formControlName="hayatChecked"
                  (change)="oncheckhayat($event)"   
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hayat
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  formControlName="casiChecked"
                  (change)="oncheckcasi($event)"
                />
                <label class="form-check-label" for="defaultCheck1"> CASI </label>
              </div>
            </div>
          </form>
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close (click)="onSubmit(editFacilityForm)" >Add</button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
  </div>
  