import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './userauth/login/login.component';
import { HomeDashboardComponent } from './dashboard/home-dashboard/home-dashboard.component';
import { UserManagementComponent } from './user_management/user-management/user-management.component';
import { HeaderComponent } from './header/header/header.component';
import { HomeComponent } from './home/home.component';
import { AdduserComponent } from './adduser/adduser.component';
import { EdituserComponent } from './edituser/edituser.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { CountryComponent } from './country/country.component';
import { ProvinceComponent } from './province/province.component';
import { DistrictComponent } from './district/district.component';
import { SubdistrictComponent } from './subdistrict/subdistrict.component';
import { UnioncouncilComponent } from './unioncouncil/unioncouncil.component';
import { VillageComponent } from './village/village.component';
import { FacilityComponent } from './facility/facility.component';
import { MappingComponent } from './mapping/mapping.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'homepage',
    component: HomeComponent,

    children: [
      {
        path: '',
        component: HomeDashboardComponent,
      },
      { path: 'usermanagement', component: UserManagementComponent },
      { path: 'adduser', component: AdduserComponent },
      { path: 'edituser/:id', component: EdituserComponent },
      { path: 'resetpassword', component: ResetpasswordComponent },
      { path: 'country', component: CountryComponent },
      { path: 'province', component: ProvinceComponent },
      { path: 'district', component: DistrictComponent },
      { path: 'tehsil', component: SubdistrictComponent },
      { path: 'unioncouncil', component: UnioncouncilComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'village', component: VillageComponent },
      { path: 'facilities', component: FacilityComponent },
      { path: 'mapping', component: MappingComponent },


      // {
      //   path: 'registerform',
      //   component: RegisterformComponent,
      // },
    ],
  },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
  // { path: 'dashboard', component: HomeDashboardComponent },

  // { path: 'user-management', component: UserManagementComponent },
  // { path: 'app-header', component: HeaderComponent },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'login' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes), // Add this line to define the routes
  ],
  exports: [RouterModule], // Export RouterModule to make its directives available in AppModule
})
export class AppRoutingModule {}
