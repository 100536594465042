<div class="outer-container">
  <div
    class="center-container"
    style="height: 90vh; width: 35vw; padding: 10px"
  >
    <mat-card>
      <div
        style="
          display: flex;
          justify-content: center;
          text-decoration: underline;
        "
      >
        <h4>Edit Tehsil</h4>
      </div>
      <div>
        <form [formGroup]="editSubDistrictForm">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Country</label>
            <input
            class="form-control form-control-lg"
            id="exampleFormControlSelect1"
            formControlName="selectedCountry"
            [value]="sub_District_Record?.country"
            name="country" readonly/>
</div>
          <div class="form-group">
            <label for="exampleInput1">Local Code</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="localCode"
              placeholder="Enter Local Code"
              formControlName="local_Code"
              [value]="sub_District_Record?.localCode"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Province</label>
            <input
            class="form-control form-control-lg"
            id="exampleFormControlSelect1"
            formControlName="selectedProvince"
            [value]="sub_District_Record?.province"
            name="province" readonly/>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">District</label>
            <select
            class="form-control"
            id="floatingSelectGrid"
            (change)="onDistrictChange($event)"
            name="district"
            formControlName="selectedDistrict"
          >
          <option [value]="sub_District_Record?.districtId">{{ sub_District_Record.district }}</option>
          <option *ngFor="let district of districts" [value]="district.id">
            {{ district.name }}
          </option>
          </select>
          </div>
          <div class="form-group">
            <label for="exampleInput1">Sub District</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="localCode"
              placeholder="Enter New District"
              formControlName="sub_District_Name"
              [value]="sub_District_Record?.name"
            />
          </div>

          <div style="display: flex; justify-content: space-between">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                formControlName="hayatChecked"
                (change)="oncheckhayat($event)"   
              />
              <label class="form-check-label" for="defaultCheck1">
                Hayat
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                formControlName="casiChecked"
                (change)="oncheckcasi($event)"

              />
              <label class="form-check-label" for="defaultCheck1"> CASI </label>
            </div>
          </div>
        </form>
        <mat-dialog-actions>
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          <button mat-button mat-dialog-close  (click)="onSubmit(editSubDistrictForm)" >Edit</button>
        </mat-dialog-actions>
      </div>
    </mat-card>
  </div>
</div>
