import { Component, OnInit, ViewChild } from '@angular/core';
import { MappingdialogueComponent } from '../mappingdialogue/mappingdialogue.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MappingeditdialogueComponent } from '../mappingeditdialogue/mappingeditdialogue.component';
import { UserService } from '../Services/user.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CMWFacilityUserVM, CMWUserVM, User } from '../Models/User';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.css'],
})
export class MappingComponent implements OnInit {
  cmwmappinguser: any;
  displayedColumns: string[] = [
    'id',
    'username',     
    'facility',
    'actions',
  ];

  pageSizeOptions = [5, 10, 25];
  dataSource: any;
  pageEvent: PageEvent = new PageEvent();
  pageSize: number = 10;
  pageIndex: number = 0;
  handlePageEvent(e: PageEvent): void {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageSize + ' ' + this.pageIndex);
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public dialog: MatDialog,private userService:UserService) {}

  ngOnInit(): void {
    this.getCMWMappingList()
  }
  dialogRef: MatDialogRef<MappingdialogueComponent> | null = null;
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filterPredicate = (data: CMWFacilityUserVM) => {
      return data.userName?.toLowerCase().includes(filterValue.trim().toLowerCase());
    };
    this.dataSource.filter = filterValue.trim().toLowerCase(); // Update the filter
  }
  
  
  
  openDialog(): void {
    const dialogRef = this.dialog.open(MappingdialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
  editDialog(Id:any): void {
    const dialogRef = this.dialog.open(MappingeditdialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
      data: {                                  
        person: {
          Cid: Id                            
        }
    }});
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }

getCMWMappingList(){
this.userService.GetCMWMappingUserList().subscribe(
(resp:any)=>{
this.cmwmappinguser= resp.result;
console.log("CMWMappingUsers",this.cmwmappinguser);
this.dataSource = new MatTableDataSource<User>(this.cmwmappinguser);
console.log('Data source:', this.dataSource); 
this.dataSource.paginator = this.paginator;


},
(err: any) => {
  console.log(err);
}
)}




UpdateUserFacilityBinding(id: number) {
  const data: CMWUserVM = {
    id: id,
    facilityId: 2147483647  
  };

  Swal.fire({
    title: 'Are you sure you want to delete the selected Facility Binding',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#008269',
    cancelButtonColor: '#008269',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.userService.UpdateUserFacilityBinding(data).subscribe(
        (response: any) => {
          if (response.success) {
            Swal.fire(
              {
                title: 'Success',
                text: "Deleted Successfully",
                icon: 'success',
                confirmButtonColor: '#008269',
                confirmButtonText: 'Ok'
              }
            );
            this.getCMWMappingList();
          } else {
            Swal.fire(
              {
                title: 'Error',
                text: "Failed to Delete !",
                icon: 'error',
                confirmButtonColor: '#008269',
                confirmButtonText: 'Ok'
              }
            );
          }
        },
        (error: any) => {
          Swal.fire(
            'Not Deleted!',
            'Failed to Delete  !',
            'error'
          );
        }
      );
    }
  });
}






}





