<div style="margin-top: -200px">
  <div class="heading text-center label">
    <p style="font-size: 25px; display: flex; justify-content: center">
      User List
    </p>
    <!-- <div style="display: flex; gap: 4px"> -->

    <!-- </div> -->
  </div>
  <div
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: -50px;
    "
  >
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        style="width: 250px; margin-bottom: -40px"
        id="searchuser"
        placeholder="Search"
        (keyup)="applyFilter($event)"
      />
    </div>
    <div style="display: flex">
      <button
        type="button"
        class="btn"
        style="
          background-color: #0d86ff;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          padding: 0px 6px;
          height: 32px;
          color: white;
          margin-bottom: -30px;
        "
        (click)="movetoaddUser()"
      >
        <span style="font-size: 20px">&#43;</span>
        Add User
      </button>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-spin"
  >
    <p style="font-size: 20px; color: white">Fetching Data...</p>
  </ngx-spinner>
  <div class="row" style="margin-top: 20px">
    <div class="container-fluid">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="date"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 30px; font-size: 13px"
          >
            S.No
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            style="font-size: 12px"
          >
            {{ pageSize * pageIndex + i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 105px; font-size: 13px"
          >
            UserName
          </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px">
            {{ element.userName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="PrivilegeName">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 105px; font-size: 13px"
          >
            Privilege
          </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px">
            {{ element.privilegeName }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="phone_number"
          style="width: 105px; font-size: 13px"
        >
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Phone
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.phoneNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Country
          </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px">
            {{ element.country }}
          </td>
        </ng-container>

        <ng-container matColumnDef="province">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Povince
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.province }}
          </td>
        </ng-container>

        <ng-container matColumnDef="district">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            District
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.district }}
          </td>
        </ng-container>
        <ng-container matColumnDef="tehsil">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Tehsil
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.tehsil }}
          </td>
        </ng-container>
        <ng-container matColumnDef="uc">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">UC</th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.uc }}
          </td>
        </ng-container>
        <ng-container matColumnDef="village">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Village
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.village }}
          </td>
        </ng-container>
        <ng-container matColumnDef="facility">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Facility
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="
              padding-right: 20px;
              padding-left: 10px;
              width: 120px;
              font-size: 12px;
            "
          >
            {{ element.facility }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="font-size: 13px">
            Actions
          </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px">
            <div class="btn-gap">
              <button
                class="btn"
                style="
                  background-color: #b9bec7;
                  border: none;
                  border-radius: 3px;
                  padding: 0px 6px;
                  height: 32px;
                  cursor: pointer;
                  color: white;
                  font-size: 13px;
                "
                [disabled]="element.disabled"
                matTooltip="Delete"
                matTooltipPosition="above"
                (click)="getpassworddetailsbyId(element.id)"
              >
                Reset
              </button>
              <button
                class="btn"
                style="
                  background-color: #0d86ff;
                  border: none;
                  border-radius: 3px;
                  padding: 0px 6px;
                  height: 32px;
                  cursor: pointer;
                  color: white;
                  font-size: 13px;
                "
                [disabled]="element.disabled"
                matTooltip="Edit User"
                tooltipClass="my-class"
                matTooltipPosition="above"
                (click)="editUser(element.id)"
              >
                Edit
              </button>
              <div class="custom-control custom-switch" style="z-index: auto">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="toggle_{{ element.id }}"
                  [checked]="!element.disabled"
                  (change)="toggleUserStatus(element)"
                />
                <label
                  style="padding-top: 3px"
                  class="custom-control-label"
                  for="toggle_{{ element.id }}"
                >
                  {{ !element.disabled ? "Enable" : "Disable" }}
                </label>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[6]"
        showFirstLastButtons
        aria-label="Select Page No."
        style="margin-bottom: 200px"
      >
      </mat-paginator>
    </div>
  </div>
</div>
