<div class="outer-container">
  <div
    class="center-container"
    style="height: 80vh; width: 35vw; padding: 10px"
  >
    <mat-card>
      <div
        style="
          display: flex;
          justify-content: center;
          text-decoration: underline;
        "
      >
        <h4>Add District</h4>
      </div>
      <div>
        <form>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Country</label>
            <select
            class="form-control form-control-lg"
            id="floatingSelectGrid"
            [(ngModel)]="selectedCountry"
            (change)="onCountryChange($event)"
            
          >
            <option [ngValue]="undefined" [disabled]="true">Select country</option>
            <option *ngFor="let country of countries" [value]="country.id">
              {{ country.name }}
            </option>
          </select>
          </div>

          <div class="form-group">
            <label for="exampleInput1">Local Code</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="localCode"
              placeholder="Enter Local Code"
              [(ngModel)]="local_Code"
              name="localCode"


            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Province</label>
            <select
            class="form-control form-control-lg"
            id="floatingSelectGrid"
            [(ngModel)]="selectedProvince"
            (change)="onProvinceChange($event)"

           >
            <option [ngValue]="undefined" [disabled]="true">Select Province</option>
            <option *ngFor="let province of provinces" [value]="province.id">
              {{ province.name }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label for="exampleInput1">District</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="district_name"
              placeholder="Enter New District"
              [(ngModel)]="district_name"
              name="district"

            />
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"                
                [(ngModel)]="selectedHayat"
                (change)="oncheckhayat($event)"                
              />
              <label class="form-check-label" for="defaultCheck1">
                Hayat
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                [(ngModel)]="selectedCasi"
                (change)="oncheckcasi($event)"
               />
              <label class="form-check-label" for="defaultCheck1"> CASI </label>
            </div>
          </div>
        </form>
        <mat-dialog-actions>
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          <button mat-button mat-dialog-close (click)="Submit()">Add</button>
        </mat-dialog-actions>
      </div>
    </mat-card>
  </div>
</div>
