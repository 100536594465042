<p
  style="
    display: flex;
    justify-content: center;
    font-size: 25px;
    margin-top: 30px;
  "
>
  ADD USER
</p>
<form style="margin-left: 100px; margin-top: 50px" #userForm="ngForm">
  <div
    style="
      display: flex;
      justify-content: flex-start;
      gap: 34px;
      margin-top: 2px;
      margin-bottom: 10px;
    "
  >
    <div class="">
      <label for="exampleFormControlInput1" class="form-label">User Name</label>
      <input
        type="text"
        class="form-control"
        style="width: 360px"
        id="exampleFormControlInput1"
        placeholder="Enter User Name"
        [(ngModel)]="user_name"
        name="userName"
        required
        #userName="ngModel"
      />
      <div *ngIf="userName.invalid && userName.touched" class="text-danger">
        User Name is Required.
      </div>
    </div>

    <div style="width: 360px">
      <label for="floatingInputGrid">Privilege</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedPrivilege"
        (change)="onPrivilegeChange($event)"
        name="selectedPrivilege"
        #Privilege="ngModel"
        required
      >
        <option disabled value="">Select Privilege</option>
        <option *ngFor="let privilege of privileges" [value]="privilege.id">
          {{ privilege.name }}
        </option>
      </select>
      <div *ngIf="Privilege.invalid && Privilege.touched" class="text-danger">
        Privilege is Required.
      </div>
    </div>
    <!-- <div>
      <div class="form-floating" style="width: 240px">
        <label for="floatingInputGrid">Health Care worker Id</label>

        <input
          class="form-control"
          id="floatingInputGrid"
          placeholder="Health Care worker Id"
          [(ngModel)]="hcw_id"
          name="hcw_id"
          #HCWID="ngModel" 
          required
        />
        <div *ngIf="HCWID.invalid && HCWID.touched" class="text-danger">
          Id is required.
        </div>
      </div>
    </div> -->
  </div>
  <div
    style="
      display: flex;
      justify-content: flex-start;
      gap: 34px;
      margin-top: 2px;
      margin-bottom: 10px;
    "
  ></div>
  <div
    style="
      display: flex;
      justify-content: flex-start;
      gap: 34px;
      margin-top: 20px;
      margin-bottom: 10px;
    "
  >
    <div>
      <div class="form-floating" style="width: 360px">
        <label for="floatingInputGrid">Health Care Worker Id</label>

        <input
          class="form-control"
          id="floatingInputGrid"
          placeholder="Health Care worker Id"
          [(ngModel)]="hcw_id"
          name="hcw_id"
          #HCWID="ngModel"
          required
        />
        <div *ngIf="HCWID.invalid && HCWID.touched" class="text-danger">
          Health Care id is Required.
        </div>
      </div>
    </div>
    <div class="form-group" style="margin-bottom: 10px">
      <label for="phonenumber">Phone Number</label>
      <input
        class="form-control"
        style="width: 360px"
        id="phonenumber"
        placeholder="Phone Number"
        [(ngModel)]="phone"
        name="phone_number"
        #PhoneNumber="ngModel"
        (keypress)="numberOnly($event)"
        autocomplete="off"
        maxlength="11"
        required
        pattern="^0[2-3][0-9]*$"
      />
      <div
        *ngIf="PhoneNumber.invalid && PhoneNumber.touched"
        class="text-danger"
      >
        Phone Number is Required.
      </div>
      <mat-error *ngIf="PhoneNumber.errors?.pattern" class="text-danger">
        Invalid phone number format. Must start with 02 or 03 and be numeric.
      </mat-error>
    </div>
  </div>
  <div
    style="
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    "
  >
    <div style="width: 237px">
      <label for="floatingInputGrid">Country</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedCountry"
        (change)="onCountryChange($event)"
      >
        <option [ngValue]="undefined" [disabled]="true">Select Country</option>
        <option *ngFor="let country of countries" [value]="country.id">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div style="width: 240px">
      <label for="floatingInputGrid">Province</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedProvince"
        (change)="onProvinceChange($event)"
      >
        <option [ngValue]="undefined" [disabled]="true">Select Province</option>
        <option *ngFor="let province of provinces" [value]="province.id">
          {{ province.name }}
        </option>
      </select>
    </div>
    <div style="width: 237px">
      <label for="floatingInputGrid">District</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedDistrict"
        (change)="onDistrictChange($event)"
        placeholder="Select District"
      >
        <option [ngValue]="undefined" [disabled]="true">Select District</option>
        <option *ngFor="let district of districts" [value]="district.id">
          {{ district.name }}
        </option>
      </select>
    </div>
  </div>
  <div
    style="
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    "
  >
    <div style="width: 240px">
      <label for="floatingInputGrid">Tehsil</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedTehsil"
        (change)="onTehsilChange($event)"
      >
        <option [ngValue]="undefined" [disabled]="true">Select Tehsil</option>
        <option *ngFor="let tehsil of tehsils" [value]="tehsil.id">
          {{ tehsil.name }}
        </option>
      </select>
    </div>
    <div style="width: 237px">
      <label for="floatingInputGrid">Union Council</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedUC"
        (change)="onUCChange($event)"
      >
        <option [ngValue]="undefined" [disabled]="true">Select UC</option>
        <option *ngFor="let uc of ucs" [value]="uc.id">{{ uc.name }}</option>
      </select>
    </div>
    <div style="width: 240px">
      <label for="floatingInputGrid">Village</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedVillage"
        (change)="onvillageChange($event)"
      >
        <option [ngValue]="undefined" [disabled]="true">Select Village</option>
        <option *ngFor="let village of villages" [value]="village.id">
          {{ village.name }}
        </option>
      </select>
    </div>
  </div>

  <div
    style="
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    "
  >
    <div style="width: 240px">
      <label for="floatingInputGrid">Facility</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        [(ngModel)]="selectedFacility"
        (change)="onfacilityChange($event)"
      >
        <option [ngValue]="undefined" [disabled]="true">Select Facility</option>
        <option *ngFor="let facility of facility" [value]="facility.id">
          {{ facility.name }}
        </option>
      </select>
    </div>

    <div class="form-group" style="margin-bottom: 10px; position: relative">
      <label for="exampleInputPassword1">Password</label>
      <div class="input-container">
        <input
          type="{{ s_Password ? 'text' : 'password' }}"
          class="form-control"
          style="width: 237px"
          id="exampleInputPassword1"
          placeholder="Password"
          [(ngModel)]="password"
          name="password"
          #Password="ngModel"
          required
        />
      </div>
      <div class="passwordtoolgle" (click)="passwordVisibility()">
        <i class="fa" [ngClass]="s_Password ? 'fa-eye' : 'fa-eye-slash'"></i>
      </div>
      <div *ngIf="Password.invalid && Password.touched" class="text-danger">
        Password is Required.
      </div>
    </div>
    <div class="form-group" style="margin-bottom: 10px; position: relative">
      <label for="confirmpassword">Confirm Password</label>

      <div class="input-container">
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          class="form-control"
          id="confirmpassword"
          style="width: 240px"
          name="confirmPassword"
          placeholder="Confirm Password"
          [(ngModel)]="confirmPassword"
          #ConfirmPassword="ngModel"
          required
        />
        <div class="confirmtoolgle" (click)="togglePasswordVisibility()">
          <i
            class="fa"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
        </div>
      </div>

      <div
        *ngIf="ConfirmPassword.invalid && ConfirmPassword.touched"
        class="text-danger"
      >
        Confirm Password is Required.
      </div>
    </div>

    <!--start actual commited code confirm password -->

    <!-- <div class="form-group" style="margin-bottom: 10px">
      <label for="confirmpassword">Confirm Password</label>
      <input
        type="{{ showPassword ? 'text' : 'password' }}"
        class="form-control"
        style="width: 240px"
        id="confirmpassword"
        name="confirmPassword"
        placeholder="Confirm Password"
        [(ngModel)]="confirmPassword"
        #ConfirmPassword="ngModel"
        required
      />
      <div class="confirmtoolgle" (click)="togglePasswordVisibility()">
        <i class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
      </div>
      <div
        *ngIf="ConfirmPassword.invalid && ConfirmPassword.touched"
        class="text-danger"
      >
        Confirm Password is Required.
      </div>
    </div> -->

    <!--end actual commited code confirm password -->


  </div>
  <!-- <div class="form-group" style="margin-bottom: 10px">
    <label for="phonenumber">Phone Number</label>
    <input
      class="form-control"
      style="width: 240px"
      id="phonenumber"
      placeholder="Phone"
      [(ngModel)]="phone"
      name="phone_number"
      #PhoneNumber="ngModel"
      (keypress)="numberOnly($event)"
      autocomplete="off"
      maxlength="11"
      required
      pattern="^0[2-3][0-9]*$"
    />
    <div *ngIf="PhoneNumber.invalid && PhoneNumber.touched" class="text-danger">
      Phone Number is required.
    </div>
    <mat-error *ngIf="PhoneNumber.errors?.pattern" class="text-danger">
      Invalid phone number format. Must start with 02 or 03 and be numeric.
    </mat-error>
  </div> -->

  <div class="form-group" style="margin-top: 32px">
    <div style="display: flex; justify-content: center; gap: 10px">
      <button
        type="submit"
        class="btn btn-primary"
        style="width: 113px; background-color: #0d86ff"
        (click)="Submit()"
        [disabled]="userForm.invalid"
      >
        Add
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        style="width: 113px; border: none; background-color: #b9bec7"
        (click)="goBack()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
