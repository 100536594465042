import { Component, OnInit } from '@angular/core';
import { UserService } from '../Services/user.service';
import { DictionaryService } from '../Services/dictionary.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mappingdialogue',
  templateUrl: './mappingdialogue.component.html',
  styleUrls: ['./mappingdialogue.component.css']
})
export class MappingdialogueComponent implements OnInit {
  facility: any = [];
  cmwuser: any = [];
  selecteduser: any = "";    
  selectedfacility: any = "";   
  userId: any;

  constructor(private router: Router,
    private toastr: ToastrService,
    private userService:UserService, private dictionaryService: DictionaryService) { }

  ngOnInit(): void {
    this.getFacilityListMapping();
    this.getCMWUSerList();
  }

getFacilityListMapping(){
this.dictionaryService.getallfacilitymapping().subscribe(
  (resp:any)=>{
    this.facility=resp.result;
  },
  (err:any)=>{
    this.toastr.error('Error','Error');
  }
)


}

getCMWUSerList(){
this.userService.GetCMWUserList().subscribe(
  (resp:any)=>{
    this.cmwuser=resp.result;
    console.log(this.cmwuser);    



  },
  (err: any) => {
    console.log(err);
  }
)




}


Submit(){
  var data={
    id: this.selecteduser,
    facilityId: this.selectedfacility,


  };
  this.userService.AddCMWUser(data).subscribe(
    (resp:any)=>{
      if(resp.success==true){
        this.toastr.success(resp.message, 'Ok');
        this.userId = resp.result;
        this.router.navigate(['/homepage/usermanagement']);
      } else {
        this.toastr.error('failed to insert data');
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data');
    }
  );}}
