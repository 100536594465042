<app-header></app-header>
<div style="display: flex">
  <div class="sidebar_bg" style="background-color: #3c4b64">
    <div id="sidebar" class="responsive_screen">
      <div class="logo-container">
        <img
          src="../../../assets/smklogo.png"
          alt="Project Logo"
          class="logo"
        />
      </div>
      <!-- <div class="sidebar__title">
        <div class="nav_icon">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div> -->
      <div class="sidebar__menu" style="padding-top: -22px">
        <div
          class="sidebar__link"
          style="margin-top: -14px; margin-bottom: 18px"
        >
          <i class="fa fa-home"></i>
          <a routerLink="/homepage/usermanagement">User Management</a>
        </div>
        <!-- <div class="sidebar__link" style="margin-top: -30px">
          <i class="fa fa-file"></i>
          <a routerLink="/homepage/terms">Terms</a>
        </div> -->
        <!-- <div class="sidebar__link" style="margin-top: -14px">
          <i class="fa fa-list"></i>
          <a routerLink="/homepage/mapping">Mapping</a>
        </div> -->
        <!-- <div class="sidebar__accordion" style="margin-top: -14px">
          <div
            class="sidebar__link locatio"
            (click)="toggleAccordion('locations')"
          >
            <i class="fa fa-map-marker"></i>
            <a class="locations__css">Locations</a>
            <i
              class="fa locations__css"
              style="margin-left: 10px"
              [ngClass]="{
                'fa-caret-down': accordionOpen.locations,
                'fa-caret-right': !accordionOpen.locations
              }"
            ></i>
          </div>
          <div
            style="background-color: #3c4b64"
            class="accordion-content"
            [class.show]="accordionOpen.locations"
          >
            <a style="color: white" routerLink="/homepage/country">Country</a>
            <a style="color: white" routerLink="/homepage/province">Province</a>
            <a style="color: white" routerLink="/homepage/district">District</a>
            <a style="color: white" routerLink="/homepage/tehsil"
              >Sub District</a
            >
            <a style="color: white" routerLink="/homepage/unioncouncil"
              >Union Council</a
            >
            <a style="color: white" routerLink="/homepage/village">Village</a>
            <a style="color: white" routerLink="/homepage/facilities"
              >Facilities</a
            >
          </div>
        </div> -->
        <div
          class="sidebar__logout"
          style="margin-top: -14px; display: flex; align-items: center"
        >
          <i class="fa fa-power-off"></i>
          <a href="#" style="color: #ffffff; text-transform: lowercase"
            >Log Out</a
          >
        </div>
      </div>
    </div>
  </div>

  <div
    class="container"
    style="display: flex; justify-content: center; align-items: center;gap: 100px;"
  >
    <router-outlet> </router-outlet>
  </div>
</div>
