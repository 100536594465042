 

     <div style="display: flex; justify-content: space-between; align-items: center;">
      <div class="input-group mb-3 mt-4" style="width: 330px;">
        <span class="input-group-text" id="basic-addon1">
          <i class="fa fa-search"></i>
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon1"
          (keyup)="applyFilter($event)"

        />
      </div>
      <div>
        <button class="circle-button" (click)="openDialog()">+</button>
      </div>
    </div>

     <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="date"
      matSortDirection="desc"
      class="table"
      style="width: 70vw; margin-top: 40px; border: 1px solid #d8dbe0;"
    >
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          S.No
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="font-size: 12px;">
          {{ pageSize * pageIndex + i + 1 }}
        </td>
      </ng-container>

       <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>
          CMW User
        </th>
        <td mat-cell *matCellDef="let element" style="font-size: 12px;">
          {{ element.userName }}
        </td>
      </ng-container>

       <ng-container matColumnDef="facility">
        <th mat-header-cell *matHeaderCellDef style="font-size: 13px;">
          Facility
        </th>
        <td mat-cell *matCellDef="let element" style="padding-right: 20px; padding-left: 10px; width: 120px; font-size: 12px;">
          {{ element.facility }}
        </td>
      </ng-container>

       <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="font-size: 13px;">
          Actions
        </th>
        <td mat-cell *matCellDef="let element" style="display: flex; gap: 8px;">
          <button class="btn" style="background-color: green; color: white;" (click)="editDialog(element.id)">
            Edit
          </button>
          <button class="btn" style="background-color: red; color: white;"  (click)="UpdateUserFacilityBinding(element.id)">
            Delete
          </button>
        </td>
      </ng-container>

       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

     <mat-paginator
      [pageSizeOptions]="[8]"
      showFirstLastButtons
      aria-label="Select Page No."
      style="margin-bottom: 20px;"
    >
    </mat-paginator>
 