import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FacilityService } from '../Services/facility.service';
import { DictionaryService } from '../Services/dictionary.service';
import { Country, Privilege, Province } from '../Models/User';
import { Observable, throwError } from 'rxjs';
import { Tehsil } from '../Models/Dictionary';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { District } from '../Models/Dictionary';
@Component({
  selector: 'app-villagedialogue',
  templateUrl: './villagedialogue.component.html',
  styleUrls: ['./villagedialogue.component.css']
})
export class VillagedialogueComponent implements OnInit {

  selectedCountry: any;
  selectedProvince: any;
  selectedDistrict: any;
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
  local_Code: any;
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: District[] = [];
  subdistricts: any;
  selectedSubDistrict: any;
  selectedUC: any;
  unioncouncil: any;
  village_name:any;
  facility: any;
  selectedFacility: any;
  constructor(
    private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getallcountry();
  }
  getallcountry() {
    this.dictionaryService.getallcountry().subscribe(
      (resp: any) => {
        this.countries = resp.result.filter(
          (country: Country) => country.name === 'Pakistan'
        );
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onCountryChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedCountry = selectedValue;

    if (selectedValue) {
      this.getallprovince(selectedValue);
    } else {
      this.provinces = [];
    }
  }

  getallprovince(countryId: any) {
    this.dictionaryService.getallprovince(countryId).subscribe(
      (resp: any) => {
        this.provinces = resp.result.filter(
          (province: Province) => province.name === 'Gilgit-Baltistan'
        );
        console.log('Province:', this.provinces);
        console.log('Initial selectedProvince:', this.selectedProvince);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onProvinceChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedProvince = selectedValue;
    if (selectedValue) {
      this.getalldistrict(selectedValue);
    } else {
      this.districts = [];
    }
  }
  getalldistrict(provinceId: any) {
    this.dictionaryService.getalldistrict(provinceId).subscribe(
      (resp: any) => {
        this.districts = resp.result;
        console.log('district:', this.districts);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onDistrictChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedDistrict = selectedValue;
    if (selectedValue) {
      this.getallsubdistrict(selectedValue);
    } else {
      this.subdistricts = [];
    }
  }
  getallsubdistrict(tehsilId: any) {
    this.dictionaryService.getalltehsil(tehsilId).subscribe(
      (resp: any) => {
        this.subdistricts = resp.result;
        console.log('Subdistrict:', this.subdistricts);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onSubDistrictChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedSubDistrict = selectedValue;
    if (selectedValue) {
      this.getalluc(selectedValue);
    } else {
      this.unioncouncil = [];
    }
  }


  getalluc(subdistrictId: any) {
    this.dictionaryService.getalluc(subdistrictId).subscribe(
      (resp: any) => {
        this.unioncouncil = resp.result;
        console.log('uc:', this.unioncouncil);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onUCChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedUC = selectedValue;
    if (selectedValue) {
      this.getallfacility(selectedValue);
    } else {
      this.facility = [];
    }
  }

  getallfacility(ucId: any) {
    this.dictionaryService.getallfacility(ucId).subscribe(
      (resp: any) => {
        this.facility = resp.result;
        console.log('facility:', this.facility);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onFacilityChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedFacility = selectedValue;
  }


  oncheckcasi(event: any) {
    if (event.target.checked) {
      console.log('casi is checked');
      this.selectedCasi = true;
    } else {
      console.log('Casi is not checked');
      this.selectedCasi = false;
    }
  }

  oncheckhayat(event: any) {
    if (event.target.checked) {
      console.log('hayat is checked');
      this.selectedHayat = true;
    } else {
      console.log('hayat is not checked');
      this.selectedHayat = false;
    }
  }

  Submit() {
    const metadata = {
      casi: this.selectedCasi,
      hayat: this.selectedHayat,
      local_code: this.local_Code,
    };
    const metadataJson = JSON.stringify(metadata);

    var data = {
      countryId: this.selectedCountry,
      provinceId: this.selectedProvince,
      districtId: this.selectedDistrict,
      tehsilId:this.selectedSubDistrict,
      ucId:this.selectedUC,
      name: this.village_name,
      name_Check: this.village_name,
      metadata: metadataJson,
      casi: this.selectedCasi,
      hayat: this.selectedHayat,
      localCode: this.local_Code,
    };
    console.log(data);
    console.log('villageName', this.village_name);
    this.dictionaryService.addvillage(data).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.toastr.success(resp.message, 'Ok');
          this.router.navigate(['/village']);
        } else {
          this.toastr.error('failed to insert data');
        }
      },
      (error: any) => {
        this.toastr.error('failed to insert data');
      }
    );
  }
}
