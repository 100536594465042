import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edituserlistdialogue',
  templateUrl: './edituserlistdialogue.component.html',
  styleUrls: ['./edituserlistdialogue.component.css']
})
export class EdituserlistdialogueComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EdituserlistdialogueComponent>) { }

  ngOnInit(): void {
  }
  closeModal() {
    this.dialogRef.close();
  }

}
