import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-termsdialogue',
  templateUrl: './termsdialogue.component.html',
  styleUrls: ['./termsdialogue.component.css']
})
export class TermsdialogueComponent implements OnInit {
selectedGroup:any;
selectedCode:any;
name_en:any;
name_ur:any;
  constructor(private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  Submit() {    
    var data = {
        group:this.selectedGroup,
        code:this.selectedCode,
        name_En:this.name_en,
        name_Ur:this.name_ur
        };
        console.log(data);
      this.dictionaryService.addtermminology(data).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.toastr.success(resp.message, 'Ok');
           this.router.navigate(['/terms']);
        } else {
          this.toastr.error('failed to insert data');
        }
      },
      (error: any) => {
        this.toastr.error('failed to insert data');
      }
    );
  }  
}
