import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProvincedialogueComponent } from '../provincedialogue/provincedialogue.component';
import { ProvinceeditdialogueComponent } from '../provinceeditdialogue/provinceeditdialogue.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { DictionaryService } from '../Services/dictionary.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { Province } from '../Models/Dictionary';

@Component({
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.css']
})
export class ProvinceComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'countryname',
    'name', 
    'actions',
  ];
  pageSizeOptions = [5, 10, 25];
  dataSource: any;
  pageEvent: PageEvent = new PageEvent();
  pageSize: number = 10;
  pageIndex: number = 0;
  country: any;
  handlePageEvent(e: PageEvent): void {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageSize + ' ' + this.pageIndex);
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public dialog: MatDialog,private toastr: ToastrService,
    private _snackBar: MatSnackBar,
    public dictionaryService: DictionaryService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getGrid();

  }
  dialogRef: MatDialogRef<ProvincedialogueComponent> | null = null;
  
  getGrid() {
    this.spinner.show();
    this.dictionaryService.getallprovincelist().subscribe(
      (resp: any) => {
        if (resp.success == true) {
          this.spinner.hide();
          console.log('Response from server:', resp.result);
          this.country = resp.result;
          console.log('Filtered User List:', this.country); 
          this.dataSource = new MatTableDataSource<Province>(this.country);
          console.log('Data source:', this.dataSource); 
          this.dataSource.paginator = this.paginator;
 
        }
      },
      (err) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
 
  openDialog(): void {
    const dialogRef = this.dialog.open(ProvincedialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
  editProvince(): void {
    const dialogRef = this.dialog.open(ProvinceeditdialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
}
