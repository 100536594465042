import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css'],
})
export class AdduserComponent implements OnInit {
  user_name: any;
  password: any;
  phone: any;
  confirmPassword: any;
  privileges: Privilege[] = [];
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: any[] = [];
  tehsils: any[] = [];
  selectedPrivilege: any;
  selectedCountry: any;
  selectedProvince: any;
  selectedDistrict: any;
  selectedTehsil: any;
  selectedUC: any;
  selectedVillage: any;
  selectedFacility: any;
  showDropdown: boolean = false;
  ucs: any[] = [];
  villages: any[] = [];
  facility: any[] = [];
  userId: any;
  hcw_id: any;
  showPassword: boolean = false;
  s_Password: boolean = false;

  SECRETKEY: string =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx7r25YBHqaoKC9KkjtYMQ4ygCjW5Ir0djeXC0SrQ3rumeGBwtSxxrNd8TjTmmiq/Xz//zgrsgNrohWAgzR/dJxPV+bqVNfAOzkG2Pph1e2qwmGJ2ptXqRnSbh924wLB0tTWqPVwGpEj5pLTSrhDMirDWFd6IvKg+dxoQK4YnYi0EttpJYvgf6X6L7tdk/4zVI+7t2MmQr60zQemChfxrggWOOOfmy69A/QfIGf25Y3HiDErqGRnMn2sTpGw4LClhujLauFcpP/Y1TN28igWPRkHXVg9PJXYLWs58/mmvxjaAWQTHRUt3wGtBamR1xz607fuyA4P9ZDVfwYdQITUScwIDAQAB';

  constructor(
    private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getallprivilege();
    this.getallcountry();
  }
  goBack() {
    this.router.navigate(['/homepage/usermanagement']);
  }

  passwordVisibility(): void {
    this.s_Password = !this.s_Password;
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  getallprivilege() {
    this.dictionaryService.getallprivilege().subscribe(
      (resp: any) => {
        this.privileges = resp.result.filter(
          (privilege: Privilege) =>
            privilege.name === 'LHS' ||
            privilege.name === 'DSV' ||
            privilege.name === 'CMW' ||
            privilege.name === 'DOCTOR'
        );

        if (!this.selectedPrivilege) {
          this.selectedPrivilege = '';
        }

        console.log('Privileges:', this.privileges);
        console.log('Initial selectedPrivilege:', this.selectedPrivilege);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onPrivilegeChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log('User selected privilege:', selectedValue);
    this.selectedPrivilege = selectedValue;
    console.log(this.selectedPrivilege);
  }

  getallcountry() {
    this.dictionaryService.getallcountry().subscribe(
      (resp: any) => {
        // Filter to only include Pakistan
        this.countries = resp.result.filter(
          (country: Country) => country.name === 'Pakistan'
        );
        // if (this.countries.length > 0) {
        //   this.selectedCountry = this.countries[0].id;
        //   this.getallprovince(this.selectedCountry);
        // }
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onCountryChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedCountry = selectedValue;

    if (selectedValue) {
      this.getallprovince(selectedValue);
    } else {
      this.provinces = [];
    }
  }

  getallprovince(countryId: any) {
    this.dictionaryService.getallprovince(countryId).subscribe(
      (resp: any) => {
        this.provinces = resp.result.filter(
          (province: Province) => province.name === 'Gilgit-Baltistan'
        );
        console.log('Province:', this.provinces);
        console.log('Initial selectedProvince:', this.selectedProvince);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onProvinceChange(event: any) {
    const selectedValueProvince = (event.target as HTMLSelectElement).value;

    this.selectedProvince = selectedValueProvince;
    if (selectedValueProvince) {
      this.getalldistrict(selectedValueProvince);
    }
  }

  getalldistrict(provinceId: any) {
    this.dictionaryService.getalldistrict(provinceId).subscribe(
      (resp: any) => {
        this.districts = resp.result;
        console.log('District:', this.districts);
        console.log('Initial selectedDistrict:', this.selectedDistrict);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onDistrictChange(event: any) {
    const selectedValueDistrict = (event.target as HTMLSelectElement).value;
    this.selectedDistrict = selectedValueDistrict;
    this.getalltehsil(this.selectedDistrict);
    this.tehsils = [];
    this.ucs = [];
    this.facility = [];
    this.villages = [];
  }

  getalltehsil(districtId: any) {
    this.dictionaryService.getalltehsil(districtId).subscribe(
      (resp: any) => {
        this.tehsils = resp.result;
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onTehsilChange(event: any) {
    const selectedValueTehsil = (event.target as HTMLSelectElement).value;
    this.selectedTehsil = selectedValueTehsil;
    this.getalluc(selectedValueTehsil);
    this.ucs = [];
    this.facility = [];
    this.villages = [];
  }

  getalluc(tehsil_id: any) {
    this.dictionaryService.getalluc(tehsil_id).subscribe(
      (resp: any) => {
        this.ucs = resp.result;
      },
      (err: any) => {
        this.toastr.error('Error');
      }
    );
  }

  onUCChange(event: any) {
    const selectedValueUC = (event.target as HTMLSelectElement).value;
    this.selectedUC = selectedValueUC;
    this.getallvillage(selectedValueUC);
    this.getallfacility(selectedValueUC);
    this.facility = [];
    this.villages = [];
  }

  getallvillage(ucId: any) {
    this.dictionaryService.getallvillage(ucId).subscribe((resp: any) => {
      this.villages = resp.result;
    });
  }

  onvillageChange(event: any) {
    const selectedValuevillage = (event.target as HTMLSelectElement).value;
    this.selectedVillage = selectedValuevillage;
    this.facility = []; // Reset facility here
    this.getallfacility(this.selectedUC); // Fetch the facilities again
  }
  getallfacility(ucId: any) {
    this.dictionaryService.getallfacility(ucId).subscribe((resp: any) => {
      this.facility = resp.result;
    });
  }
  onfacilityChange(event: any) {
    const selectedValuefacility = (event.target as HTMLSelectElement).value;
    this.selectedFacility = selectedValuefacility;
  }
  encryptData(data: any, publicKey: string) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(data);
  }

  Submit() {
    if (this.password === this.confirmPassword) {
      const metadata = {
        uc_id: this.selectedUC,
        hcw_id: this.hcw_id,
        full_name: this.user_name,
        tehsil_id: this.selectedTehsil,
        country_id: this.selectedCountry,
        village_id: this.selectedVillage,
        district_id: this.selectedDistrict,
        facility_id: this.selectedFacility,
        province_id: this.selectedProvince,
        phone_number: this.phone,
        server_number: 0,
      };
      const metadataJson = JSON.stringify(metadata);

      var data = {
        username: this.user_name,
        password: this.encryptData(this.password, this.SECRETKEY),
        salt: '',
        addedBy: 0,
        countryId: this.selectedCountry,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        ucId: this.selectedUC,
        facilityId: this.selectedFacility,
        villageId: this.selectedVillage,
        metadata: metadataJson,
        privilege: this.selectedPrivilege,
        tehsilId: this.selectedTehsil,
        hcW_Id: this.hcw_id,
        fullName: this.user_name,
        phoneNumber: this.phone,
        accessLevel: 0,
      };
      console.log(this.selectedPrivilege);
      this.userService.AddNewUser(data).subscribe(
        (resp: any) => {
          if (resp.success) {
            this.toastr.success(resp.message, 'Ok');
            this.userId = resp.result;
            this.router.navigate(['/homepage/usermanagement']);
          } else {
            this.toastr.error('failed to insert data');
          }
        },
        (error: any) => {
          this.toastr.error('failed to insert data');
        }
      );
    } else this.toastr.error('password not match');
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
