import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import JSEncrypt from 'jsencrypt';
import { UserAuthService } from 'src/app/Services/user-auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  // loginForm= FormGroup;
  username: any;
  pass: any;
  loginId: any[] = [];
  encryptMod: any;
  plainText: string = 'testing encryption';
  cypherText: string = '';

  constructor(
    private router: Router,
    private userauthService: UserAuthService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}
  showPassword: boolean = false;
  SECRETKEY: string =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx7r25YBHqaoKC9KkjtYMQ4ygCjW5Ir0djeXC0SrQ3rumeGBwtSxxrNd8TjTmmiq/Xz//zgrsgNrohWAgzR/dJxPV+bqVNfAOzkG2Pph1e2qwmGJ2ptXqRnSbh924wLB0tTWqPVwGpEj5pLTSrhDMirDWFd6IvKg+dxoQK4YnYi0EttpJYvgf6X6L7tdk/4zVI+7t2MmQr60zQemChfxrggWOOOfmy69A/QfIGf25Y3HiDErqGRnMn2sTpGw4LClhujLauFcpP/Y1TN28igWPRkHXVg9PJXYLWs58/mmvxjaAWQTHRUt3wGtBamR1xz607fuyA4P9ZDVfwYdQITUScwIDAQAB';

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  login() {
    //  this.router.navigate(['/homepage/usermanagement']);

    var data = {
      username: this.username,
      //password: 'xlvNFXHo4dfAzr6rIz+3Afs/mneu+hKdLMbHXe8vUu0sHpdZGSiS/BBbHK0oGCqtQJyQaMoWXi1jaUQ7twZG05Ws7U+aa/teBoMm3WqbwtCvZH2dI1yB+vRRsUa5lOTnmJFqdzm8ffvX70dU/Fg5/SXXeXXEAdo5rtCs9B1A71jn8w8sCtuxiuajUyYQV+R9ZpS9T/oK5KHldV7dTF9Z/k2vh+S2+5Pm5mb1OVSfDoVZ6TbBxSkmu1nRj8yxBID4dQF8Q9x6164QR6ez1d2sIGy9OGSoafOYbD98lB1YPJdkItgXCPVqOUS6WvTAoI24UfGS6tZAxz39wiKI76/rbw==',
      password: this.encryptData(this.pass, this.SECRETKEY),
      //password: this.encrypt()
    };
    //console.log(" eeeee  : "+ this.pass);

    if (this.username == null || this.pass == null) return;

    this.userauthService.loginuser(data).subscribe(
      (resp: any) => {
        if (resp.success == true) {
          this.loginId = resp.result;

          this.toastr.success("Login Successful");

          console.log(this.loginId);
          this.router.navigate(['/homepage/usermanagement']);
        } else {
          this.toastr.error("Failed to Login");
        }
      },
      (err: any) => {
        this.toastr.error('Invalid email or password', 'Error');
      }
    );
  }

  encryptData(data: any, publicKey: string) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(data);
  }

  encrypt() {
    //console.log(publicKey);
    this.encryptMod.setPublicKey(this.SECRETKEY);
    console.log(this.encryptMod);
    this.cypherText = this.encryptMod.encrypt(JSON.stringify(this.pass));
    this.pass = this.cypherText;
    console.log('encryptedText-', this.cypherText);
    //this.decrypt();
    //return this.cypherText;
  }
}
