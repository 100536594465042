<div class="outer-container">
    <div
      class="center-container"
      style="height: 110vh; width: 35vw; padding-top: 50px;padding-bottom: 20px;"
    >
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >

          <h4>Add Union Council</h4>
        </div>
        <div>
          <form>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Country</label>
              <select
              class="form-control form-control-lg" 
              id="floatingSelectGrid"
              [(ngModel)]="selectedCountry"
              (change)="onCountryChange($event)"
              
            >
              <option [ngValue]="undefined" [disabled]="true">Select country</option>
              <option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
              </option>
            </select>
            </div>
  
            <div class="form-group">
              <label for="exampleInput1">Local Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter Local Code"
                [(ngModel)]="local_Code"
                name="local_code"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Province</label>
              <select
              class="form-control"
              id="floatingSelectGrid"
              [(ngModel)]="selectedProvince"
              (change)="onProvinceChange($event)"
            >
              <option [ngValue]="undefined" [disabled]="true">Select Province</option>
              <option *ngFor="let province of provinces" [value]="province.id">
                {{ province.name }}
              </option>
            </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">District</label>
              <select
              class="form-control"
              id="floatingSelectGrid"
              [(ngModel)]="selectedDistrict"
              (change)="onDistrictChange($event)"
              placeholder="Select District"
            >
              <option [ngValue]="undefined" [disabled]="true">Select District</option>
              <option *ngFor="let district of districts" [value]="district.id">
                {{ district.name }}
              </option>
            </select>
            </div>
            <div class="form-group">
              <label for="exampleInput1">Sub District</label>
              <select
              class="form-control"
              id="floatingSelectGrid"
              [(ngModel)]="selectedSubDistrict"
              (change)="onSubDistrictChange($event)"
              placeholder="Select Sub District"
            >
              <option [ngValue]="undefined" [disabled]="true">Select SubDistrict</option>
              <option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.id">
                {{ subdistrict.name }}
              </option>
            </select>
             </div>
            <div class="form-group">
                <label for="exampleInput1">UC</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="localCode"
                  placeholder="Enter New UC"
                  [(ngModel)]="uc_name"
                  name="uc_name"

                />
              </div>
  
            <div style="display: flex; justify-content: space-between">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  [(ngModel)]="selectedHayat"
                  (change)="oncheckhayat($event)"    
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hayat
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  [(ngModel)]="selectedCasi"
                  (change)="oncheckcasi($event)"
                />
                <label class="form-check-label" for="defaultCheck1"> CASI </label>
              </div>
            </div>
          
          </form>
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close (click)="Submit()" >Add</button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
  </div>
  