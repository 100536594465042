<div style="background-color: red">
  <div id="sidebar" [ngClass]="status ? 'sidebar_responsive' : ''">
    <div>
      <!-- <div class="sidebar__img">
        <h1>SMK AdminPanel</h1>
      </div> -->
      <div class="nav_icon" (click)="addToggle()">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </div>
    </div>
    <div class="sidebar__menu">
      <div class="sidebar__link active_menu_link">
        <i class="fa fa-home"></i>
        <a href="dashboard">Dashboard</a>
      </div>
      <h2>MNG</h2>
      <div class="sidebar__link">
        <i class="fa fa-user" aria-hidden="true"></i>
        <a href="user-management">User Management</a>
      </div>
      <!-- <div class="sidebar__link">
      <i class="fa fa-building-o"></i>
      <a href="#">Country Management</a>
    </div>
    <div class="sidebar__link">
      <i class="fa fa-wrench"></i>
      <a href="#">Province Management</a>
    </div>
    <div class="sidebar__link">
      <i class="fa fa-archive"></i>
      <a href="#"> District Management</a>
    </div>
    <div class="sidebar__link">
      <i class="fa fa-archive"></i>
      <a href="#">Tehsil Management </a>
    </div>
    <div class="sidebar__link">
      <i class="fa fa-archive"></i>
      <a href="#">UC Management</a>
    </div>
    <div class="sidebar__link">
      <i class="fa fa-archive"></i>
      <a href="#"> Village Management</a>
    </div>
    <div class="sidebar__link">
      <i class="fa fa-archive"></i>
      <a href="#">Facility</a>
    </div> -->
      <!-- Any additional sidebar links go here -->
      <div class="sidebar__logout">
        <i class="fa fa-power-off"></i>
        <a href="#">Log out</a>
      </div>
    </div>
  </div>
</div>
