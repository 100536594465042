import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubdistrictdialogueComponent } from '../subdistrictdialogue/subdistrictdialogue.component';
import { SubdistricteditdialogueComponent } from '../subdistricteditdialogue/subdistricteditdialogue.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DictionaryService } from '../Services/dictionary.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { Tehsil } from '../Models/Dictionary';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subdistrict',
  templateUrl: './subdistrict.component.html',
  styleUrls: ['./subdistrict.component.css']
})
export class SubdistrictComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'countryname',
    'provincename',
    'districtname',
    'name', 
    'actions',
  ];

  pageSizeOptions = [5, 10, 25];
  dataSource: any;
  pageEvent: PageEvent = new PageEvent();
  pageSize: number = 10;
  pageIndex: number = 0;
  country: any;
  unionCouncil: any;
  loader=true;

  handlePageEvent(e: PageEvent): void {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageSize + ' ' + this.pageIndex);
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(public dialog: MatDialog,private toastr: ToastrService,
    private _snackBar: MatSnackBar,
    public dictionaryService: DictionaryService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getGrid();
  }
  dialogRef: MatDialogRef<SubdistrictdialogueComponent> | null = null;
  getGrid() {
    this.spinner.show();
    this.dictionaryService.getalltehsillist().subscribe(
      (resp: any) => {
        if (resp.success == true) {
          this.spinner.hide();
          console.log('Response from server:', resp.result);
          this.unionCouncil = resp.result;
          console.log('Filtered User List:', this.unionCouncil); 
          this.dataSource = new MatTableDataSource<Tehsil>(this.unionCouncil);
          console.log('Data source:', this.dataSource); 
          this.dataSource.paginator = this.paginator;
 
        }
      },
      (err) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(SubdistrictdialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
  editDialog(id:any): void {
    const dialogRef = this.dialog.open(SubdistricteditdialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
      data: {                                  
        person: {
          Cid: id                            
        }
    }});
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
  disableSubDistrict(id:number){
    Swal.fire({
      title: 'Are you sure you want to delete the selected Sub District?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#008269',
      cancelButtonColor: '#008269',
      confirmButtonText: 'Yes, disable it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var res = this.dictionaryService.disabledtehsil(id,true)
      .subscribe(
        (response: any) => {
          setTimeout(() => {
            this.loader = false;
          }, 1000);
          if(response.success) {
            Swal.fire(
              {
                title: 'Success',
                text: " Deleted Successfully",
                icon: 'success',
                confirmButtonColor: '#008269',
                confirmButtonText: 'Ok'
              }
            );
            this.getGrid();
          } else {
            Swal.fire(
              {title: 'Error',
              text: "Failed to Delete !",
              icon: 'error',
              confirmButtonColor: '#008269',
              confirmButtonText: 'Ok'}
            )  
          }
        },
        (error: any) => {
          Swal.fire(
            'Not Deleted!',
            'Failed to Delete  !',
            'error'
          )
        }
      );}
    })
  }
}
