import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../Services/user-auth.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  userPasswordData: any;
  resetForm: FormGroup;
  NEXT_PUBLIC_SECRETKEY: string =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx7r25YBHqaoKC9KkjtYMQ4ygCjW5Ir0djeXC0SrQ3rumeGBwtSxxrNd8TjTmmiq/Xz//zgrsgNrohWAgzR/dJxPV+bqVNfAOzkG2Pph1e2qwmGJ2ptXqRnSbh924wLB0tTWqPVwGpEj5pLTSrhDMirDWFd6IvKg+dxoQK4YnYi0EttpJYvgf6X6L7tdk/4zVI+7t2MmQr60zQemChfxrggWOOOfmy69A/QfIGf25Y3HiDErqGRnMn2sTpGw4LClhujLauFcpP/Y1TN28igWPRkHXVg9PJXYLWs58/mmvxjaAWQTHRUt3wGtBamR1xz607fuyA4P9ZDVfwYdQITUScwIDAQAB';
  new_Password: boolean = false;
  newConfirm_Password: boolean = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private userAuthService: UserAuthService,
    private _snackBar: MatSnackBar
  ) {
    this.resetForm = new FormGroup(
      {
        userName: new FormControl('', Validators.required),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
      },
      { validators: this.passwordsMatchValidator }
    );
  }

  newpasswordVisibility(): void {
    this.new_Password = !this.new_Password;
  }
  newConfirmPasswordVisibility(): void {
    this.newConfirm_Password = !this.newConfirm_Password;
  }

  ngOnInit(): void {
    this.userAuthService.getUserData().subscribe((userPasswordData) => {
      if (userPasswordData) {
        console.log('User data:', userPasswordData);
        this.userPasswordData = userPasswordData;

        console.log('User data after assignment:', this.userPasswordData);
        this.resetForm.patchValue(this.userPasswordData);
        console.log('Form value after patchValue:', this.resetForm.value);
      }
    });
  }
  goBack() {
    this.router.navigate(['/homepage/usermanagement']);
  }

  CreatePostObjNew(form: any): any {
    var P = this.resetForm.value;
    if (P.newPassword != P.confirmPassword) {
      this.toastr.error('Password Doesnot Match', 'OK');
      return;
    }
    console.log(form);

    var pp = {
      //newPassword: 'xlvNFXHo4dfAzr6rIz+3Afs/mneu+hKdLMbHXe8vUu0sHpdZGSiS/BBbHK0oGCqtQJyQaMoWXi1jaUQ7twZG05Ws7U+aa/teBoMm3WqbwtCvZH2dI1yB+vRRsUa5lOTnmJFqdzm8ffvX70dU/Fg5/SXXeXXEAdo5rtCs9B1A71jn8w8sCtuxiuajUyYQV+R9ZpS9T/oK5KHldV7dTF9Z/k2vh+S2+5Pm5mb1OVSfDoVZ6TbBxSkmu1nRj8yxBID4dQF8Q9x6164QR6ez1d2sIGy9OGSoafOYbD98lB1YPJdkItgXCPVqOUS6WvTAoI24UfGS6tZAxz39wiKI76/rbw==',
      newPassword: this.encryptData(
        P.newPassword.toString(),
        this.NEXT_PUBLIC_SECRETKEY
      ),
      id: this.userPasswordData.id,
    };
    return pp;
  }

  onSubmit(form: FormGroup): void {
    var data = this.CreatePostObjNew(form);
    this.userAuthService.resetpassword(data).subscribe(
      (response: any) => {
        if (response.success) {
          this.toastr.success(response.message, 'Ok');
          this.router.navigate(['/homepage/usermanagement']);
        } else {
          this.toastr.error(response.message, 'Ok');
        }
      },
      (error: any) => {
        this.toastr.error('failed to insert data', 'OK');
        //this.patientForm.reset();
      }
    );
  }

  encryptData(data: any, publicKey: string) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(data);
  }

  passwordsMatchValidator(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get('newPassword');
    const confirmPassword = control.get('confirmPassword');
    if (
      newPassword &&
      confirmPassword &&
      newPassword.value !== confirmPassword.value
    ) {
      return { passwordsMismatch: true };
    }
    return null;
  }
}
