<div class="outer-container">
  <div
    class="center-container"
    style="height: 110vh; width: 35vw; padding-top: 50px; padding-bottom: 20px"
  >
    <mat-card>
      <div
        style="
          display: flex;
          justify-content: center;
          text-decoration: underline;
        "
      >
        <h4>Edit Union Council</h4>
      </div>
      <div>
        <form [formGroup]="editucForm" >
          <div class="form-group">
            <label for="exampleFormControlSelect1">Country</label>
            <input
            class="form-control form-control-lg"
            id="exampleFormControlSelect1"
            formControlName="selectedCountry"
            [value]="ucRecord?.country"
            name="country" readonly/>
          </div>

          <div class="form-group">
            <label for="exampleInput1">Local Code</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="localCode"
              name="localcode"
              placeholder="Enter Local Code"
              formControlName="local_Code"
              [value]="ucRecord?.localCode"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Province</label>
            <input
            class="form-control form-control-lg"
            id="exampleFormControlSelect1"
            formControlName="selectedProvince"
            [value]="ucRecord?.province"
            name="province" readonly/>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">District</label>
            <select
            class="form-control"
            id="floatingSelectGrid"            
            (change)="onDistrictChange($event)"
            formControlName="selectedDistrict"
            >
             <option [value]="ucRecord?.districtId">{{ ucRecord?.district }}</option>
            <option *ngFor="let district of districts" [value]="district.id">
              {{ district.name }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label for="exampleInput1">Sub District</label>
            <select
            class="form-control"
            id="floatingSelectGrid"
             placeholder="Select Sub District"
             (change)="onSubDistrictChange($event)"
             formControlName="selectedSubDistrict">
             <option [value]="ucRecord?.tehsilId">{{ ucRecord?.tehsil }}</option>

            <option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.id">
              {{ subdistrict.name }}
            </option>
          </select>
          </div>
          <div class="form-group">
            <label for="exampleInput1">UC</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="localCode"
              placeholder="Enter New UC"
              formControlName="uc_name"

            />
          </div>

          <div style="display: flex; justify-content: space-between">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                formControlName="hayatChecked"
                (change)="oncheckhayat($event)"   
              />
              <label class="form-check-label" for="defaultCheck1">
                Hayat
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                formControlName="casiChecked"
                (change)="oncheckcasi($event)"
              />
              <label class="form-check-label" for="defaultCheck1"> CASI </label>
            </div>
          </div>
        </form>
        <mat-dialog-actions>
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          <button mat-button mat-dialog-close (click)="onSubmit(editucForm)">Edit</button>
        </mat-dialog-actions>
      </div>
    </mat-card>
  </div>
</div>
