<div>
  <!-- time start -->
  <!-- class="container" -->
  <!-- time end -->

  <!-- <app-header></app-header> -->
  <!-- <div id="sidebar" [ngClass]="status? 'sidebar_responsive' : ''">
    <app-sidebar></app-sidebar>
  </div> -->
  <div class="dashboard-container">
    <router-outlet></router-outlet>
  </div>
</div>
