<div style="
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
  ">
  <form [formGroup]="resetForm">
    <p style="
        display: flex;
        justify-content: center;
        font-size: 25px;
        margin-top: 30px;
      ">
      RESET PASSWORD
    </p>

    <div class="">
      <label for="username" class="form-label">Username</label>
      <input type="text" class="form-control" style="width: 500px; background-color: white" id="username"
        placeholder="admin" formControlName="userName" [value]="userPasswordData?.userName" />
      <div *ngIf="
          resetForm.controls.userName.invalid &&
          resetForm.controls.userName.touched
        " class="text-danger">
        Username is required.
      </div>
    </div>
    <!-- <div class="form-group" style="margin-bottom: 10px">
      <label for="password">Password</label>
      <input
        type="password"
        class="form-control"
        style="width: 500px; background-color: white"
        id="password"
        placeholder="password"
        formControlName="password"
        [value]="userPasswordData?.password"
      />
    </div> -->
    <div class="form-group" style="margin-bottom: 10px; position: relative">
      <label for="country" style="margin-top: 10px">New Password</label>
      <div class="input-container">
        <input type="{{ new_Password ? 'text' : 'password' }}" class="form-control"
          style="width: 500px; background-color: white" id="password" formControlName="newPassword"
          placeholder="change password" required />
        <div class="passwordtoolgle" (click)="newpasswordVisibility()">
          <i class="fa" [ngClass]="new_Password ? 'fa-eye' : 'fa-eye-slash'"></i>
        </div>
      </div>

      <div *ngIf="
          resetForm.controls.newPassword.invalid &&
          resetForm.controls.newPassword.touched
        " class="text-danger">
        Password is required and must be at least 6 characters long.
      </div>
    </div>

    <!-- <div class="form-group" style="margin-bottom: 10px; position: relative">
      <label for="country" style="margin-top: 10px">New Password</label>
      <div class="input-container">
        <input
          type="{{ new_Password ? 'text' : 'password' }}"
          class="form-control"
          style="width: 500px; background-color: white"
          id="password"
          formControlName="newPassword"
          placeholder="change password"
          required
        />
      </div>
      <div class="passwordtoolgle" (click)="newpasswordVisibility()">
        <i class="fa" [ngClass]="new_Password ? 'fa-eye' : 'fa-eye-slash'"></i>
      </div>

      <div
        *ngIf="
          resetForm.controls.newPassword.invalid &&
          resetForm.controls.newPassword.touched
        "
        class="text-danger"
      >
        Password is required and must be at least 6 characters long.
      </div>
    </div> -->
    <div class="form-group" style="margin-bottom: 10px; position: relative">
      <label for="confirmPassword">Confirm Password</label>
      <div class="input-container">
        <input type="{{ newConfirm_Password ? 'text' : 'password' }}" class="form-control"
          style="width: 500px; background-color: white" id="confirmPassword" formControlName="confirmPassword"
          placeholder="confirm password" required />
        <div class="passwordtoolgleconfirm" (click)="newConfirmPasswordVisibility()">
          <i class="fa" [ngClass]="newConfirm_Password ? 'fa-eye' : 'fa-eye-slash'"></i>
        </div>
      </div>
      <div *ngIf="resetForm.controls.confirmPassword.invalid && resetForm.controls.confirmPassword.touched"
        class="text-danger">
        Confirm password is required and must be at least 6 characters long.
      </div>
      <div *ngIf="resetForm.errors?.passwordsMismatch && resetForm.controls.confirmPassword.touched"
        class="text-danger">
        Passwords do not match.
      </div>
    </div>

    <div style="
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
      ">
      <button type="submit" class="btn btn-primary" style="width: 100px; background-color: #0d86ff"
        (click)="onSubmit(resetForm)" [disabled]="resetForm.invalid">
        Save
      </button>
      <button type="submit" style="width: 100px; border: none; background-color: #b9bec7" class="btn btn-primary"
        (click)="goBack()">
        Cancel
      </button>
    </div>
  </form>
</div>