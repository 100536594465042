<form
  style="display: flex; flex-direction: column; padding-bottom: 10px"
  [formGroup]="editForm"
>
  <p
    style="
      display: flex;
      justify-content: center;
      font-size: 25px;
      margin-top: 30px;
    "
  >
    EDIT USER
  </p>
  <div>
    <div
      style="
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 2px;
        margin-bottom: 10px;
      "
    >
      <div class="">
        <label for="username" class="form-label">User Name</label>
        <input
          type="text"
          class="form-control"
          style="width: 230px"
          id="username"
          placeholder="admin"
          formControlName="userName"
          [value]="userData?.userName"
          name="username"
        />
      </div>

      <div>
        <label for="phone">Phone Number</label>

        <input
          type="text"
          class="form-control"
          style="width: 253px"
          id="phone"
          placeholder="Phone Number"
          formControlName="phoneNumber"
          name="selectedPhone"
          [value]="userData?.phoneNumber"
        />
      </div>
    </div>
    <!-- <div style="width: 500px">
      <label for="floatingInputGrid">Country</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        name="country"
        formControlName="country"
        (change)="onCountryChange($event)"
      >
        <option *ngFor="let country of countries" [value]="country.id">
          {{ country.name }}
        </option>
      </select>
    </div> -->
    <div style="width: '500'">
      <div class="form-floating">
        <label for="healthcareworkerid">Health Care worker Id</label>

        <input
          type="text"
          class="form-control"
          id="healthcareworkerid"
          placeholder="Health Care worker Id"
          formControlName="hcwId"
          name="selectedHCWId"
          [value]="userData?.hcwId"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 2px;
        margin-bottom: 10px;
      "
    >
      <div style="width: 237px">
        <label for="floatingInputGrid">Country</label>

        <select
          class="form-control"
          id="floatingSelectGrid"
          name="country"
          formControlName="country"
          (change)="onCountryChange($event)"
        >
          <option *ngFor="let country of countries" [value]="country.id">
            {{ country.name }}
          </option>
        </select>
      </div>
      <!-- <div style="width: '237px'">
        <div class="form-floating">
          <label for="healthcareworkerid">Health Care worker Id</label>

          <input
            type="text"
            class="form-control"
            id="healthcareworkerid"
            placeholder="Health Care worker Id"
            formControlName="hcwId"

            name="selectedHCWId"
            [value]="userData?.hcwId"
          />
        </div>
      </div> -->
      <div style="width: 240px">
        <label for="province">Province</label>

        <select
          class="form-control"
          id="province"
          (change)="onProvinceChange($event)"
          formControlName="province"
          name="province"
        >
          <option [value]="userData?.provinceId">
            {{ userData?.province }}
          </option>
          <option *ngFor="let province of provinces" [value]="province.id">
            {{ province.name }}
          </option>
        </select>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 2px;
        margin-bottom: 10px;
      "
    >
      <div style="width: 237px">
        <label for="floatingInputGrid">District</label>

        <select
          class="form-control"
          id="floatingSelectGrid"
          (change)="onDistrictChange($event)"
          name="district"
          formControlName="district"
        >
          <option [value]="userData?.districtId">
            {{ userData?.district }}
          </option>
          <option *ngFor="let district of districts" [value]="district.id">
            {{ district.name }}
          </option>
        </select>
      </div>
      <div style="width: 240px">
        <label for="floatingInputGrid">Tehsil</label>

        <select
          class="form-control"
          id="floatingSelectGrid"
          (change)="onTehsilChange($event)"
          name="tehsil"
          formControlName="tehsil"
        >
          <option [value]="userData?.tehsilId">{{ userData?.tehsil }}</option>
          <option *ngFor="let tehsil of tehsils" [value]="tehsil.id">
            {{ tehsil.name }}
          </option>
        </select>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 2px;
        margin-bottom: 10px;
      "
    >
      <div style="width: 237px">
        <label for="floatingInputGrid">Union Council</label>

        <select
          class="form-control"
          id="floatingSelectGrid"
          (change)="onUCChange($event)"
          name="uc"
          formControlName="uc"
        >
          <option [value]="userData?.ucId">{{ userData?.uc }}</option>
          <option *ngFor="let uc of ucs" [value]="uc.id">{{ uc.name }}</option>
        </select>
      </div>

      <div style="width: 240px">
        <label for="floatingInputGrid">Village</label>

        <select
          class="form-control"
          id="floatingSelectGrid"
          name="village"
          formControlName="village"
          (change)="onVillageChange($event)"
        >
          <option [value]="userData?.villageId">{{ userData?.village }}</option>
          <option *ngFor="let village of villages" [value]="village.id">
            {{ village.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group" style="width: 500px">
      <label for="floatingInputGrid">Please Select Facility</label>

      <select
        class="form-control"
        id="floatingSelectGrid"
        name="facility"
        formControlName="facility"
      >
        <option [value]="userData?.facilityId">{{ userData?.facility }}</option>
        <option *ngFor="let facility of facility" [value]="facility.id">
          {{ facility.name }}
        </option>
      </select>
    </div>

    <div style="display: flex; justify-content: center; gap: 10px">
      <button
        type="submit"
        class="btn btn-primary"
        style="width: 100px; background-color: #0d86ff"
        (click)="onSubmit(editForm)"
      >
        Edit
      </button>
      <button
        type="submit"
        style="width: 100px; background-color: #b9bec7; border: none"
        class="btn btn-primary"
        (click)="goBack()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
