<div class="outer-container">
  <div class="center-container" style="height: 50vh">
    <mat-card>
      <div>
        <form>
          <div class="form-group">
            <label for="exampleFormControlSelect1">CMW User</label>
            <select
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              [(ngModel)]="selecteduser"
              name="cmwUser"
            >
            <option value="" disabled>Please select</option>
            <option *ngFor="let user of cmwuser" [value]="user.id">{{user.userName}}</option>
 
            </select>
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Facility</label>
            <select
              class="form-control form-control-lg"
              id="exampleFormControlSelect2"
              [(ngModel)]="selectedfacility"
              name="facility" 
            >
            <option value="" disabled selected>Please select</option>
            <option *ngFor="let facility of facility" [value]="facility.id">{{facility.name}}</option>
        
            </select>
          </div>
        </form>

        <mat-dialog-actions>
          <button mat-button mat-dialog-close>No</button>
          <button mat-button mat-dialog-close cdkFocusInitial     (click)="Submit()">Submit</button>
        </mat-dialog-actions>
      </div>
    </mat-card>
  </div>
</div>
