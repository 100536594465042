<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Login Page</title>
  </head>
  <style>
    body {
      font-family: Arial, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0;
      background-color: #f0f2f5;
    }

    .container {
      width: 90%;
      max-width: 400px;
      margin-top: 40px;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .login-form img.logo {
      max-width: 100%;
      height: auto;
    }

    .login-form h2 {
      margin: 20px 0;
      font-size: 1.5rem;
      text-align: center;
      color: #333;
    }

    .login-form form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .login-form input {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .eyetoolgle {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      cursor: pointer;
      padding: 5px;
    }

    .login-form button {
      width: 100%;
      padding: 10px;
      margin: 20px 0;
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
    }

    @media (max-width: 600px) {
      .container {
        padding: 15px;
      }

      .login-form h2 {
        font-size: 1.2rem;
      }

      .login-form button {
        margin-top: 15px;
      }

      .eyetoolgle {
        right: 10px;
      }
    }
  </style>
  <body>
    <div class="container">
      <div class="login-form">
        <img
          src="../../../assets/smklogo.png"
          alt="Project Logo"
          class="logo"
        />
        <h2>SehatMand Khandan</h2>
        <form>
          <input
            type="text"
            placeholder="Username"
            id="username"
            name="username"
            [(ngModel)]="username"
            autocomplete="off"
          />
          <div style="position: relative; width: 100%">
            <input
              type="{{ showPassword ? 'text' : 'password' }}"
              placeholder="Password"
              id="password"
              required
              [(ngModel)]="pass"
              name="password"
              style="width: 100%"
            />
            <div class="eyetoolgle" (click)="togglePasswordVisibility()">
              <i
                class="fa"
                [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              ></i>
            </div>
          </div>
          <button
            type="submit"
            style="background-color: #321fdb"
            (click)="login()"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  </body>
</html>
