import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-termseditdialogue',
  templateUrl: './termseditdialogue.component.html',
  styleUrls: ['./termseditdialogue.component.css']
})
export class TermseditdialogueComponent implements OnInit {

  editTermForm: FormGroup;
  district_name:any;
  termRecord: any;
  termId: any;
   constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService) { 
      this.editTermForm = new FormGroup({
        selectedGroup: new FormControl(''),
        selectedCode: new FormControl(''),
        selected_name_en: new FormControl(''),
        selected_name_ur: new FormControl(''),
  

      });



    }

  ngOnInit(): void {
    this.GetTerminologyRecord();
    this.termId = this.data.person.Cid;
    console.log(this.termId)
   }

 

 
 
  
 
 
GetTerminologyRecord() {
  this.dictionaryService.gettermbyprimaryid(this.data.person.Cid).subscribe(
    (response: any) => {
      if(response.success == true){
        this.termRecord = response.result[0];
        console.log(this.termRecord);
        this.editTermForm.patchValue({
          selectedGroup:this.termRecord?.group,
          selectedCode:this.termRecord?.code,
          selected_name_en: this.termRecord.name_en,    
          selected_name_ur:this.termRecord.name_ur,
        

               
         });

      }       
    },
    (err: any) => {
      console.log(err);
    }
  )
}


CreatePostObjNew(form: any): any {
  var P = this.editTermForm.value;
  console.log(form);
   
  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    uuid:this.termRecord.uid,
    group:P.selectedGroup,
    code:P.selectedCode, 
    name_En: P.selected_name_en,
    name_Ur: P.selected_name_ur
  
   };
  return pp;
  console.log(pp);
}
 
  onSubmit(editTermForm: FormGroup) :void{
    var data = this.CreatePostObjNew(editTermForm);
   this.dictionaryService.editterminology(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok');
         this.router.navigate(['/term']);
      } else {
        this.toastr.error('failed to insert data');
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data');
    }
  );
}  
  

}
