<div class="outer-container">
    <div
      class="center-container"
      style="height: 80vh; width: 35vw; padding: 10px"
    >
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >
          <h4>Edit District</h4>
        </div>
        <div>
          <form [formGroup]="editDistrictForm">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Country</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="selectedCountry"
              [value]="districtRecord?.country"
              name="country" readonly/>
            </div>
  
            <div class="form-group">
              <label for="exampleInput1">Local Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter Local Code"
                formControlName="local_Code"
                [value]="districtRecord?.localCode"

              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Province</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="selectedProvince"
              [value]="districtRecord?.province"
              name="province" readonly/>
            </div>
            <div class="form-group">
              <label for="exampleInput1">District</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter New District"
                formControlName="district_name"

              />
            </div>
            <div style="display: flex; justify-content: space-between">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  formControlName="hayatChecked"
                  (change)="oncheckhayat($event)"   
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hayat
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  formControlName="casiChecked"
                  (change)="oncheckcasi($event)"

                />
                <label class="form-check-label" for="defaultCheck1"> CASI </label>
              </div>
            </div>
          </form>
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close  (click)="onSubmit(editDistrictForm)" >Edit </button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
  </div>
  