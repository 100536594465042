import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
 import { Router } from '@angular/router';
 import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { Tehsil } from '../Models/Dictionary';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-unioncounceldialogue',
  templateUrl: './unioncounceldialogue.component.html',
  styleUrls: ['./unioncounceldialogue.component.css']
})
export class UnioncounceldialogueComponent implements OnInit {
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: any[] = [];
  subdistricts: Tehsil[]=[];
  selectedCountry: any;
  selectedProvince: any;
  selectedDistrict: any;
  selectedSubDistrict:any;
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
  local_Code:any;
  uc_name:any;
  constructor(private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getallcountry();

  }
  getallcountry() {
    this.dictionaryService.getallcountry().subscribe(
      (resp: any) => {
        // Filter to only include Pakistan
        this.countries = resp.result.filter((country: Country) => country.name === 'Pakistan');
        // if (this.countries.length > 0) {
        //   this.selectedCountry = this.countries[0].id;
        //   this.getallprovince(this.selectedCountry);
        // }
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onCountryChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedCountry = selectedValue;

    if (selectedValue) {
      this.getallprovince(selectedValue);
    } else {
      this.provinces = [];
    }
  }

  getallprovince(countryId: any) {
    this.dictionaryService.getallprovince(countryId).subscribe(
      (resp: any) => {
        this.provinces = resp.result.filter((province: Province) => province.name === 'Gilgit-Baltistan');
        console.log('Province:', this.provinces);
        console.log('Initial selectedProvince:', this.selectedProvince);
   
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  
  onProvinceChange(event: any) {
    const selectedValueProvince = (event.target as HTMLSelectElement).value;

    this.selectedProvince = selectedValueProvince; 
    if (selectedValueProvince) {
      this.getalldistrict(selectedValueProvince);
    }  
  }
  

  getalldistrict(provinceId: any) {
    this.dictionaryService.getalldistrict(provinceId).subscribe(
      (resp: any) => {
        this.districts = resp.result;
        console.log('District:', this.districts);
        console.log('Initial selectedDistrict:', this.selectedDistrict);
   
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onDistrictChange(event: any) {
    const selectedValueDistrict = (event.target as HTMLSelectElement).value;
    this.selectedDistrict = selectedValueDistrict;
    if(selectedValueDistrict){
      this.getalltehsil(selectedValueDistrict);
     }
  }
  getalltehsil(districtId: any) {
    this.dictionaryService.getalltehsil(districtId).subscribe(
      (resp: any) => {
        this.subdistricts = resp.result;
     
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onSubDistrictChange(event: any) {
    const selectedValueTehsil = (event.target as HTMLSelectElement).value;
    this.selectedSubDistrict = selectedValueTehsil;
   
  }

  oncheckcasi(event:any){
    if (event.target.checked) {
      console.log('casi is checked');
      this.selectedCasi = true;
    } else {
      console.log('Casi is not checked');
      this.selectedCasi=false;
    }
  }
  
  oncheckhayat(event:any){
    if (event.target.checked) {
      console.log('hayat is checked');
      this.selectedHayat = true;
    } else {
      console.log('hayat is not checked');
      this.selectedHayat=false;
    }
  }
  
  
  
    Submit() {

    const metadata = {
     casi: this.selectedCasi,
     hayat: this.selectedHayat,
     local_code:this.local_Code,
    };
    const metadataJson = JSON.stringify(metadata);
   
   
    var data = {
      provinceId: this.selectedProvince,
      countryId: this.selectedCountry,
      districtId:this.selectedDistrict,
      tehsilId:this.selectedSubDistrict,
      name: this.uc_name,
      metadata: metadataJson,
      name_Check: this.uc_name,
      casi:this.selectedCasi,
      hayat:this.selectedHayat,
      localCode:this.local_Code
        };
        console.log(data);
        console.log("UCName", this.uc_name)
     this.dictionaryService.adduc(data).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.toastr.success(resp.message, 'Ok');
           this.router.navigate(['/unioncouncil']);
        } else {
          this.toastr.error('failed to insert data');
        }
      },
      (error: any) => {
        this.toastr.error('failed to insert data');
      }
    );
  }  
}
