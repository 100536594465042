import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../Services/user.service';
import { DictionaryService } from '../Services/dictionary.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { User } from '../Models/User';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mappingeditdialogue',
  templateUrl: './mappingeditdialogue.component.html',
  styleUrls: ['./mappingeditdialogue.component.css']
})
export class MappingeditdialogueComponent implements OnInit {

  facility: any[] = [];
  cmwusers: any[] = [];
  filtercmwusers: any[] = [];
  selectedfacility: any = "";   
  userId: any;
  CMWUserId: any;
  userData: any;
  CMWUser: any;
  form: FormGroup;
  myControluser=new FormControl('');
  myControlFacility=new FormControl('');
  filteredOptionsuser: Observable<any[]>;
  filteredOptionsfacility: Observable<any[]>;
  optionsuser: any[] = [];
  optionsfacility: any[] = [];
  selectUserId: any;
  filterfacility: any=[]=[];
  selectFacilityId: any;
  selectedfacilityId: any;
  facilityname:any;
  username:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,    
  private router: Router,
  private fb: FormBuilder,
    private toastr: ToastrService,
    private userService:UserService, private dictionaryService: DictionaryService) { 
      this.form = new FormGroup({
        username: new FormControl(''),
        myControlFacility: new FormControl(''),
      });
 
    }

  ngOnInit(): void {
    this.getFacilityListMapping();
    this.getCMWUSerList();
    this.GetCMWUserRecord();
    this.CMWUserId = this.data.person.Cid;
    console.log("CMWUserId" , this.CMWUserId)

  }
  GetCMWUserRecord() {
    this.userService.GetUserByPrimaryId(this.data.person.Cid).subscribe(
      (response: any) => {
        if(response.success == true){
          this.userData = response.result[0];
          this.form.patchValue({
            username: this.userData.userName,           
            myControlFacility: this.userData?.facilityId
           });

        }       
      },
      (err: any) => {
        console.log(err);
      }
    )
  }
 
 
     getFacilityListMapping() {
      this.dictionaryService.getallfacilitymapping().subscribe(
        (resp: any) => {
       
          this.facility = resp.result.filter((facility: any) => 
            facility.id !== this.userData?.facilityId
          );
       
    }
  )}

 



getCMWUSerList(){
this.userService.GetCMWUserList().subscribe(
  (resp:any)=>{
    this.cmwusers=resp.result;
    
    console.log(this.cmwusers);    
 
   
  },
  (err: any) => {
    console.log(err);
  }
)
 
}


CreatePostObjNew(form: any): any {
  var P = this.form.value;
  console.log(form);

  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    id:this.userData.id,
    facilityId: P.myControlFacility,
   
 
    
   };
  return pp;
}
 
onSubmit(form: FormGroup): void {
 
  var data = this.CreatePostObjNew(form);
  this.userService.EditCMWUserMapping(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok' );
        this.userId=resp.result;
        this.router.navigate(['/homepage/usermanagement']);

      } else {
        this.toastr.success(resp.message, 'Ok' );
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data', 'OK'
      );
    }
  );

}

toggleUserStatus(user: any): void {
  const newStatus = !user.disabled;
  const action = newStatus ? 'Disable' : 'Enable';

  Swal.fire({
    title: `Are you sure you want to ${action.toLowerCase()} the selected user?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#0d86ff',
    cancelButtonColor: '#d33',
    confirmButtonText: `Yes, ${action} it!`
  }).then((result) => {
    if (result.isConfirmed) {
      // Create data object to send to the backend
      const data = {
        id: user.id,
        facilityId: '2147483648',
      };

      this.userService.UpdateUserFacilityBinding(data).subscribe(
        (resp: any) => {
          if (resp.success) {
            Swal.fire({
              title: 'Success',
              text: `User ${action}d Successfully`,
              icon: 'success',
              confirmButtonColor: '#0d86ff',
              confirmButtonText: 'Ok'
            });
            // Update the local state of the user
            const index = this.userData.findIndex((u: any) => u.id === user.id);
            if (index !== -1) {
              this.userData[index].disabled = newStatus;
            }
          } else {
            Swal.fire({
              title: 'Error',
              text: `Failed to ${action} user!`,
              icon: 'error',
              confirmButtonColor: '#0d86ff',
              confirmButtonText: 'Ok'
            });
          }
        },
        (err) => {
          Swal.fire('Error', `Failed to ${action} user!`, 'error');
        }
      );
    }
  });
}






}
