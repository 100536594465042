<div class="outer-container">
    <div
      class="center-container"
      style="height: 90vh; width: 35vw; padding: 10px"
    >
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >
          <h4>Add New Terms</h4>
        </div>
        <div>
          <form>
         
  
            <div class="form-group">
              <label for="exampleInput1">Groups</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Group"
                [(ngModel)]="selectedGroup"
                name="group"

              />
            </div>
            <div class="form-group">
                <label for="exampleInput1"> Code</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="localCode"
                  placeholder="Enter  Code"
                  [(ngModel)]="selectedCode"
                  name="code"
                />
              </div>
             

              <div class="form-group">
                <label for="exampleInput1"> Name (en)</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="localCode"
                  placeholder="Enter  en"
                  [(ngModel)]="name_en"
                  name="name_en"
                />
              </div>
              <div class="form-group">
                <label for="exampleInput1"> Name (ur)</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="localCode"
                  placeholder="Enter  ur"
                  [(ngModel)]="name_ur"
                  name="name_ur"
                />
              </div>
          </form>
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close (click)="Submit()">Add</button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
  </div>
  