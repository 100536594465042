import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(private router: Router) { }
  
  ngOnInit(): void {}
  accordionOpen: Record<any, boolean> = {
    locations: false,
  };

  toggleAccordion(location: any) {
    this.accordionOpen[location] = !this.accordionOpen[location];
  }
  status = false;
  addToggle()
  {
    this.status = !this.status;       
  }
  navigateToUserManagement() {
    this.router.navigate(['/usermanagement']);
  }
}
