import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { District } from '../Models/Dictionary';

@Component({
  selector: 'app-subdistrictdialogue',
  templateUrl: './subdistrictdialogue.component.html',
  styleUrls: ['./subdistrictdialogue.component.css'],
})
export class SubdistrictdialogueComponent implements OnInit {
  selectedCountry: any;
  selectedProvince: any;
  selectedDistrict: any;
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
  local_Code: any;
  subdistrict_name: any;
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: District[] = [];
  constructor(
    private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getallcountry();
  }
  getallcountry() {
    this.dictionaryService.getallcountry().subscribe(
      (resp: any) => {
        this.countries = resp.result.filter(
          (country: Country) => country.name === 'Pakistan'
        );
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  onCountryChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedCountry = selectedValue;

    if (selectedValue) {
      this.getallprovince(selectedValue);
    } else {
      this.provinces = [];
    }
  }

  getallprovince(countryId: any) {
    this.dictionaryService.getallprovince(countryId).subscribe(
      (resp: any) => {
        this.provinces = resp.result.filter(
          (province: Province) => province.name === 'Gilgit-Baltistan'
        );
        console.log('Province:', this.provinces);
        console.log('Initial selectedProvince:', this.selectedProvince);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onProvinceChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedProvince = selectedValue;
    if (selectedValue) {
      this.getalldistrict(selectedValue);
    } else {
      this.districts = [];
    }
  }
  getalldistrict(provinceId: any) {
    this.dictionaryService.getalldistrict(provinceId).subscribe(
      (resp: any) => {
        this.districts = resp.result;
        console.log('district:', this.districts);
      },
      (err: any) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
  onDistrictChange(event: any) {
    const selectedValue = event.target.value;
    this.selectedDistrict = selectedValue;
  }

  oncheckcasi(event: any) {
    if (event.target.checked) {
      console.log('casi is checked');
      this.selectedCasi = true;
    } else {
      console.log('Casi is not checked');
      this.selectedCasi = false;
    }
  }

  oncheckhayat(event: any) {
    if (event.target.checked) {
      console.log('hayat is checked');
      this.selectedHayat = true;
    } else {
      console.log('hayat is not checked');
      this.selectedHayat = false;
    }
  }

  Submit() {
    const metadata = {
      casi: this.selectedCasi,
      hayat: this.selectedHayat,
      local_code: this.local_Code,
    };
    const metadataJson = JSON.stringify(metadata);

    var data = {
      provinceId: this.selectedProvince,
      countryId: this.selectedCountry,
      districtId: this.selectedDistrict,
      name: this.subdistrict_name,
      metadata: metadataJson,
      name_Check: this.subdistrict_name,
      casi: this.selectedCasi,
      hayat: this.selectedHayat,
      local_Code: this.local_Code,
    };
    console.log(data);
    console.log('districtName', this.subdistrict_name);
    this.dictionaryService.addtehsil(data).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.toastr.success(resp.message, 'Ok');
          this.router.navigate(['/subdistrict']);
        } else {
          this.toastr.error('failed to insert data');
        }
      },
      (error: any) => {
        this.toastr.error('failed to insert data');
      }
    );
  }
}
