<div class="outer-container">
  <div
    class="center-container"
    style="height: 90vh; width: 35vw; padding: 10px; padding-top: 30px"
  >
    <mat-card>
      <div
        style="
          display: flex;
          justify-content: center;
          text-decoration: underline;
        "
      >
        <h4>Add Facility</h4>
      </div>
      <div>
        <form style="width: 28vw"    >
          <div style="display: flex; justify-content: space-between; gap: 3px">
            <div class="form-group">
              <label for="countryForm">Country</label>
              <select
                class="form-control form-control-lg"
                id="countryForm"
                 style="width: 213px"
                 [(ngModel)]="selectedCountry"
              (change)="onCountryChange($event)"              >
                <option [ngValue]="undefined" [disabled]="true">
                  Select country
                </option>
                <option *ngFor="let country of countries" [value]="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="localCode">Local Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter Local Code"
                [(ngModel)]="local_Code"
                name="localCode"
              />
            </div>
          </div>

          <div style="display: flex; justify-content: space-between">
            <div class="form-group">
              <label for="provinceForm">Province</label>
              <select
                class="form-control form-control-lg"
                id="provinceForm"
                style="width: 213px"
                [(ngModel)]="selectedProvince"
                (change)="onProvinceChange($event)"
              >
                <option [ngValue]="undefined" [disabled]="true">
                  Select Province
                </option>
                <option *ngFor="let province of provinces" [value]="province.id">
                  {{ province.name }}
                </option>
                <!-- <option value="1">Please select</option>
                <option value="2">kpk</option> -->
              </select>
            </div>

            <div class="form-group">
              <label for="districtForm">District</label>
              <select
                class="form-control form-control-lg"
                id="districtForm"
                 style="width: 213px"
                 [(ngModel)]="selectedDistrict"
                (change)="onDistrictChange($event)"              >
                <option [ngValue]="undefined" [disabled]="true">
                  Select District
                </option>
                <option
                *ngFor="let district of districts" [value]="district.id"
                >
                  {{ district.name }}
                </option>
                <!-- <option value="1">Please select</option>
                <option value="2">kpk</option> -->
              </select>
            </div>
          </div>

          <div style="display: flex; gap: 5px">
            <div class="form-group">
              <label for="hayatForm">Sub District</label>
              <select
                class="form-control form-control-lg"
                id="hayatForm"
                style="width: 213px"
                [(ngModel)]="selectedSubDistrict"
                (change)="onSubDistrictChange($event)"    
              >
                <option [ngValue]="undefined" [disabled]="true">
                  Select SubDistrict
                </option>
                <option
                  *ngFor="let subdistrict of subdistricts"
                  [value]="subdistrict.id"
                >
                  {{ subdistrict.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="ucForm">UC</label>
              <select
                class="form-control form-control-lg"
                id="hayatForm"
                style="width: 213px"
                [(ngModel)]="selectedUC"
                (change)="onUCChange($event)"    
                name="uc"
                >
                <option [ngValue]="undefined" [disabled]="true">
                  Select UC
                </option>
                <option
                  *ngFor="let uc of unioncouncil"
                  [value]="uc.id">
                  {{ uc.name }}
                </option>  
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="subDistrictForm">Facility</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="subDistrictForm"
              placeholder="Enter New Facility"
              [(ngModel)]="facility_name"
              name="facility"
            />
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                [(ngModel)]="selectedHayat"
                (change)="oncheckhayat($event)"
              />
              <label class="form-check-label" for="defaultCheck1">Hayat</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="defaultCheck2"
                [(ngModel)]="selectedCasi"
                (change)="oncheckcasi($event)"
              />
              <label class="form-check-label" for="defaultCheck2">CASI</label>
            </div>
          </div>

          <mat-dialog-actions>
            <button
              mat-button
              mat-dialog-close
              cdkFocusInitial
              style="background-color: teal; color: #ffffff"
            >
              Cancel
            </button>
            <button
              mat-button
              type="submit"
              mat-dialog-close         
              style="background-color: #0f58a5; color: #ffffff"
              (click)="Submit()"
              >
              Add
            </button>
          </mat-dialog-actions>
        </form>
      </div>
    </mat-card>
  </div>
</div>
