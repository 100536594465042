<div class="outer-container">
  <div class="center-container" style="height: 50vh">
    <mat-card>
      <div
        style="
          display: flex;
          justify-content: center;
          text-decoration: underline;
        "
      >
        <h4>Edit User</h4>
      </div>

      <div>
        <form [formGroup]="form">
          <div class="form-group">
            <label for="exampleFormControlSelect1">CMW User</label>
            <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="username"
              [value]="userData?.userName"
              name="username" readonly/>
            
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Facility</label>
            <select
            class="form-control form-control-lg"
            id="exampleFormControlSelect2"
            formControlName="myControlFacility"
            name="facility" 
          >
          <option [value]="userData?.facilityId">{{ userData?.facility }}</option>
          <option *ngFor="let userfacility of facility" [value]="userfacility.id">
            {{ userfacility.name }}
          </option>
          </select>
          </div>
        </form> 

        <mat-dialog-actions>
          <button mat-button mat-dialog-close>No</button>
          <button mat-button mat-dialog-close cdkFocusInitial (click)="onSubmit(form)"  >Edit</button>
        </mat-dialog-actions>
      </div>
    </mat-card>
  </div>
</div>
