import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { UserauthModule } from './userauth/userauth.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { DashboardModule } from './dashboard/dashboard.module';

import { UserManagementModule } from './user_management/user-management/user-management.module';
import { RouterModule } from '@angular/router';
import { HeaderModule } from './header/header/header.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HomeComponent } from './home/home.component';
import { EdituserlistdialogueComponent } from './edituserlistdialogue/edituserlistdialogue.component';
import { AdduserComponent } from './adduser/adduser.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EdituserComponent } from './edituser/edituser.component';
import { FormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ToastrModule } from 'ngx-toastr';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { CountryComponent } from './country/country.component';
import { ProvinceComponent } from './province/province.component';
import { DistrictComponent } from './district/district.component';
import { SubdistrictComponent } from './subdistrict/subdistrict.component';
import { UnioncouncilComponent } from './unioncouncil/unioncouncil.component';
import { VillageComponent } from './village/village.component';
import { FacilityComponent } from './facility/facility.component';
import { CountrydialogueComponent } from './countrydialogue/countrydialogue.component';
import {MatButtonModule} from '@angular/material/button';
import { MappingComponent } from './mapping/mapping.component';
import { MappingdialogueComponent } from './mappingdialogue/mappingdialogue.component';
import { MappingeditdialogueComponent } from './mappingeditdialogue/mappingeditdialogue.component';
import { ProvinceeditdialogueComponent } from './provinceeditdialogue/provinceeditdialogue.component';
import { ProvincedialogueComponent } from './provincedialogue/provincedialogue.component';
import { CountryeditdialogueComponent } from './countryeditdialogue/countryeditdialogue.component';
import { DistrictdialogueComponent } from './districtdialogue/districtdialogue.component';
import { DistricteditdialogueComponent } from './districteditdialogue/districteditdialogue.component';
import { SubdistrictdialogueComponent } from './subdistrictdialogue/subdistrictdialogue.component';
import { SubdistricteditdialogueComponent } from './subdistricteditdialogue/subdistricteditdialogue.component';
import { UnioncounceldialogueComponent } from './unioncounceldialogue/unioncounceldialogue.component';
import { UnioncounceleditdialogueComponent } from './unioncounceleditdialogue/unioncounceleditdialogue.component';
import { VillagedialogueComponent } from './villagedialogue/villagedialogue.component';
import { VillageeditdialogueComponent } from './villageeditdialogue/villageeditdialogue.component';
import { FacilitydialogueComponent } from './facilitydialogue/facilitydialogue.component';
import { FacilityeditdialogueComponent } from './facilityeditdialogue/facilityeditdialogue.component';
import { TermsComponent } from './terms/terms.component';
import { TermsdialogueComponent } from './termsdialogue/termsdialogue.component';
import { TermseditdialogueComponent } from './termseditdialogue/termseditdialogue.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EdituserlistdialogueComponent,
    AdduserComponent,
    EdituserComponent,
    ResetpasswordComponent,
    CountryComponent,
    ProvinceComponent,
    DistrictComponent,
    SubdistrictComponent,
    UnioncouncilComponent,
    VillageComponent,
    FacilityComponent,
    CountrydialogueComponent,
    MappingComponent,
    MappingdialogueComponent,
    MappingeditdialogueComponent,
    ProvinceeditdialogueComponent,
    ProvincedialogueComponent,
    CountryeditdialogueComponent,
    DistrictdialogueComponent,
    DistricteditdialogueComponent,
    SubdistrictdialogueComponent,
    SubdistricteditdialogueComponent,
    UnioncounceldialogueComponent,
    UnioncounceleditdialogueComponent,
    VillagedialogueComponent,
    VillageeditdialogueComponent,
    FacilitydialogueComponent,
    FacilityeditdialogueComponent,
    TermsComponent,
    TermsdialogueComponent,
    TermseditdialogueComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    BrowserModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatCardModule,
    UserauthModule,
    DashboardModule,
    MatDialogModule,
    MatOptionModule,
    RouterModule,
    UserManagementModule,
    HeaderModule,
    AppRoutingModule,
    SidebarModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
