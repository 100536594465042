import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, headers } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }
  private userDataSubject = new BehaviorSubject<any>(null);

  setUserData(userData: any) {
    this.userDataSubject.next(userData);
  }

  getUserData() {
    return this.userDataSubject.asObservable();
  }
  GetAllUserList(){
    var url= environment.baseURLNew+'api/User/get_user_list';
    let res=this.http.get(url,{headers:headers});
    return res;
  }
  GetUserByPrimaryId(Id: any) {
    var url = environment.baseURLNew + 'api/User/get_user_list_by_Id?Id=' + Id;
    let res = this.http.get(url, { headers: headers });
    return res;
  }
AddNewUser(payload:any){
  var url=environment.baseURLNew+'api/User/add_new_user';
  let res=this.http.post(url,payload,{headers:headers});
  return res;
}

EditUser(payload:any){
  var url=environment.baseURLNew+'api/User/edit_user_details';
  let res=this.http.post(url,payload,{headers:headers});
  return res;
}

DisableUser(UserId: number,disabled:boolean){
  var url=`${environment.baseURLNew}api/User/disable_user_details?Id=${UserId}&Disable=${disabled}`;
  let res=this.http.get(url,{headers:headers});
  return res;
}

GetCMWUserList(){
  var url=environment.baseURLNew+'api/User/GetCMWUserList';
  let res=this.http.get(url,{headers:headers});
  return res;
}
GetCMWMappingUserList(){
  var url=environment.baseURLNew+'api/User/get_cmw_user_mapping';
  let res=this.http.get(url,{headers:headers});
  return res;
}
AddCMWUser(payload:any){
  var url=environment.baseURLNew+'api/User/add-cmw-user-mapping';
  let res=this.http.post(url,payload,{headers:headers});
  return res;
}
EditCMWUserMapping(payload:any){
  var url=environment.baseURLNew+'api/User/edit-cmw-user-mapping';
  let res=this.http.post(url,payload,{headers:headers});
  return res;
}


UpdateUserFacilityBinding(payload:any){
  var url=environment.baseURLNew+'api/User/update_user_facility_binding';
  let res=this.http.post(url,payload,{headers:headers});
  return res;
}
}
