  import { Component, Inject, OnInit } from '@angular/core';
  import { Tehsil } from '../Models/Dictionary';
  import { DictionaryService } from '../Services/dictionary.service';
  import { UserService } from '../Services/user.service';
  import { MatSnackBar } from '@angular/material/snack-bar';
  import { Router } from '@angular/router';
  import { ToastrService } from 'ngx-toastr';
  import { Country, Privilege, Province } from '../Models/User';
  import { MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { FormControl, FormGroup } from '@angular/forms';
  
  @Component({
    selector: 'app-unioncounceleditdialogue',
    templateUrl: './unioncounceleditdialogue.component.html',
    styleUrls: ['./unioncounceleditdialogue.component.css']
  })
  export class UnioncounceleditdialogueComponent implements OnInit {
    editucForm: FormGroup;

    districts: any[] = [];
    subdistricts: Tehsil[]=[];
    selectedCountry: any;
    selectedProvince: any;
    selectedDistrict: any;
    selectedSubDistrict:any;
    selectedHayat: boolean = false;
    selectedCasi: boolean = false;
    local_Code:any;
    ucRecord: any;
    uc_Id: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
      private dictionaryService: DictionaryService,
      private userService: UserService,
      private _snackBar: MatSnackBar,
      private toastr: ToastrService) { 

        this.editucForm = new FormGroup({
          selectedCountry: new FormControl(''),
          seletedProvince: new FormControl(''),
          local_Code: new FormControl(''),
          selectedDistrict: new FormControl(''),
          selectedSubDistrict: new FormControl(''),
          uc_name:new FormControl(''),
          hayatChecked:new FormControl(''),
          casiChecked:new FormControl('')
  
        });
  
  


      }

    ngOnInit(): void {
      this.GetUCRecord();
      this.uc_Id = this.data.person.Cid;

    }
    getalldistrict(provinceId:any){
      this.dictionaryService.getalldistrict(provinceId).subscribe(
        (resp:any)=>{
          this.districts=resp.result;
          this.districts = resp.result.filter((district: any) => 
            district.id !== this.ucRecord.districtId
          );
    
        },
        (err:any)=>{
          this.toastr.error('Error','Error');
        }
      )
    }
    onDistrictChange(event:any){
      const selectedValueDistrict = (event.target as HTMLSelectElement).value;
      this.selectedDistrict=selectedValueDistrict;
      if(selectedValueDistrict){
        this.getallsubdistrict(selectedValueDistrict);
       }
    
    }


    getallsubdistrict(districtId:any){
      this.dictionaryService.getalltehsil(districtId).subscribe(
        (resp:any)=>{
          this.subdistricts=resp.result;
          this.subdistricts = resp.result.filter((subdistrict: any) => 
            subdistrict.id !== this.ucRecord.districtId
          );
    
        },
        (err:any)=>{
          this.toastr.error('Error','Error');
        }
      )
    }
    onSubDistrictChange(event:any){
      const selectedValueSubDistrict = (event.target as HTMLSelectElement).value;
      this.selectedSubDistrict=selectedValueSubDistrict;

    
    }

  oncheckcasi(event:any){
  if (event.target.checked) {
    console.log('casi is checked');
    this.selectedCasi = true;
  } else {
    console.log('Casi is not checked');
    this.selectedCasi=false;
  }
  }

  oncheckhayat(event:any){
  if (event.target.checked) {
    console.log('hayat is checked');
    this.selectedHayat = true;
  } else {
    console.log('hayat is not checked');
    this.selectedHayat=false;
  }
  }

  GetUCRecord() {
    this.dictionaryService.GetUCByPrimaryId(this.data.person.Cid).subscribe(
      (response: any) => {
        if(response.success == true){
          this.ucRecord = response.result[0];
          this.editucForm.patchValue({
            selectedCountry:this.ucRecord?.country,
            selectedProvince:this.ucRecord?.province,
            selectedDistrict:this.ucRecord.districtId,            
            selectedSubDistrict: this.ucRecord?.tehsilId,  
            uc_name:this.ucRecord?.name,              
            casiChecked:this.ucRecord.casi,
            hayatChecked: this.ucRecord.hayat,
            local_Code:this.ucRecord.localCode

                
          });  
          this.getalldistrict(this.ucRecord.provinceId);
          this.getallsubdistrict(this.ucRecord.districtId);
        }       
      },
      (err: any) => {
        console.log(err);
      }
    )
  }

  CreatePostObjNew(form: any): any {
    var P = this.editucForm.value;
    console.log(form);
    const metadata = {
      casi: P.selectedCasi,
      hayat: P.selectedHayat,
      local_code:P.local_Code,
     };
     const metadataJson = JSON.stringify(metadata);
    //  const name={
    //    en:P.district_name,
    //    ur:P.district_name
    //  };
    //  const nameJson=JSON.stringify(name);
    var pp = {
      score: '' + P.score,
      result: '' + P.result,
      id:this.ucRecord.id,
      districtId:P.selectedDistrict,
      tehsilId:P.selectedSubDistrict,
      name: P.uc_name,
      metadata: metadataJson,
      name_Check: P.uc_name,
      casi:P.casiChecked,
      hayat:P.hayatChecked,
      localCode:P.local_Code
      
   
      
     };
    return pp;
    console.log(pp);
  }
   
    onSubmit(editucForm: FormGroup) :void{
      var data = this.CreatePostObjNew(editucForm);
     this.dictionaryService.edituc(data).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.toastr.success(resp.message, 'Ok');
          
           this.router.navigate(['/unioncouncil']);
        } else {
          this.toastr.error('failed to insert data');
        }
      },
      (error: any) => {
        this.toastr.error('failed to insert data');
      }
    );
  }  


  
  }
