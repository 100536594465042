import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/Services/user.service';
import { User } from 'src/app/Models/User';
import { EdituserlistdialogueComponent } from 'src/app/edituserlistdialogue/edituserlistdialogue.component';
import { EdituserComponent } from 'src/app/edituser/edituser.component';
import { UserAuthService } from 'src/app/Services/user-auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {

  Enable:number;
  userlist: User[] = [];
  userData: any = {};
  displayedColumns: string[] = [
    'id',
    'username',
    'PrivilegeName',
    'phone_number',
    'country',
    'province',
    'district',
    'tehsil',
    'uc',
    'village',
    'facility',
    'actions',
  ];

  pageSizeOptions = [5, 10, 25];
  dataSource: any;
  isButtonDisabled: boolean = false;
  loader = true;
  pageEvent: PageEvent = new PageEvent();
  pageSize: number = 10;
  pageIndex: number = 0;

  handlePageEvent(e: PageEvent): void {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageSize + ' ' + this.pageIndex);
  }

  constructor(
    private toastr: ToastrService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public userService: UserService,
    private router: Router,
    public userAuthService: UserAuthService,
    private spinner: NgxSpinnerService
  ) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.getGrid();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }

  filterByUserName() {
    let filterFunction = (data: User, filter: string): boolean => {
      if (filter) {
        const subjects = data.Username;
        for (let i = 0; i < subjects.length; i++) {
          if (subjects[i].indexOf(filter) != -1) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    };
    return filterFunction;
  }

  openUserDialog(): void {
    this.dialog.open(EdituserlistdialogueComponent);
  }
  movetoaddUser() {
    this.router.navigate(['homepage/adduser']);
  }
  movetoeditUser(Id: any) {
    this.getuserbyId(Id);
    this.router.navigate(['homepage/edituser']);
  }

  resetpassword() {
    this.router.navigate(['homepage/resetpassword']);
  }

  getGrid() {
    this.spinner.show();
    this.userService.GetAllUserList().subscribe(
      (resp: any) => {
        if (resp.success == true) {
          this.spinner.hide();
          console.log('Response from server:', resp.result);
          this.userlist = resp.result.filter((user: any) => {
            return user.privilege === 3 || user.privilege === 70 || user.privilege === 72 || user.privilege === 73;
          });
          console.log('Filtered User List:', this.userlist); 
          this.dataSource = new MatTableDataSource<User>(this.userlist);
          console.log('Data source:', this.dataSource); 
          this.dataSource.paginator = this.paginator;
 
        }
      },
      (err) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }

  getuserbyId(Id: any) {
    this.userService.GetUserByPrimaryId(Id).subscribe(
      (response: any) => {
        if (response.success == true) {
          const userData = response.result[0];
          this.userService.setUserData(userData);
  
          this.router.navigate(['homepage/edituser']);
        }
      },
      (err: any) => {
        this.toastr.error(err);
      }
    );
  }

  editUser(id: any): void {
    this.router.navigate(['homepage/edituser', id]);
  }
  getpassworddetailsbyId(Id: any) {
    this.userAuthService.getpassworddetailsbyId(Id).subscribe(
      (response: any) => {
        if (response.success == true) {
          const userPasswordData = response.result[0];
          this.userAuthService.setUserPasswordData(userPasswordData);
          this.router.navigate(['homepage/resetpassword']);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  toggleUserStatus(user: any): void {
    const newStatus = !user.disabled;
    const action = newStatus ? 'Disable' : 'Enable';
  
    Swal.fire({
      title: `Are you sure you want to ${action.toLowerCase()} the selected user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0d86ff',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${action} it!`
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.DisableUser(user.id, newStatus).subscribe(
          (resp: any) => {
            if (resp.success) {
              Swal.fire({
                title: 'Success',
                text: `User ${action}d Successfully`,
                icon: 'success',
                confirmButtonColor: '#0d86ff',
                confirmButtonText: 'Ok'
              });
              this.getGrid();
              // Update the local state of the user
              const index = this.userlist.findIndex((u: any) => u.id === user.id);
              if (index !== -1) {
                this.userlist[index].disabled = !newStatus;
                this.dataSource.data = this.userlist;
              }
            } else {
              Swal.fire({
                title: 'Error',
                text: `Failed to ${action} user!`,
                icon: 'error',
                confirmButtonColor: '#0d86ff',
                confirmButtonText: 'Ok'
              });
            }
          },
          (err) => {
            Swal.fire('Error', `Failed to ${action} user!`, 'error');
          }
        );
      }
    });
  }
  
}
