import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provinceeditdialogue',
  templateUrl: './provinceeditdialogue.component.html',
  styleUrls: ['./provinceeditdialogue.component.css']
})
export class ProvinceeditdialogueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
