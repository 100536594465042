import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provincedialogue',
  templateUrl: './provincedialogue.component.html',
  styleUrls: ['./provincedialogue.component.css']
})
export class ProvincedialogueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
