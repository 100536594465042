import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, headers } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  constructor(private http:HttpClient) { }
  private userPasswordDataSubject = new BehaviorSubject<any>(null);

  setUserPasswordData(userPasswordData: any) {
    this.userPasswordDataSubject.next(userPasswordData);
  }

  getUserData() {
    return this.userPasswordDataSubject.asObservable();
  }

  loginuser(payload:any){
    
    var url = environment.baseURLNew + 'api/Login/login_user';
    let res = this.http.post(url,payload,{headers:headers});
    return res;
  }
  resetpassword(payload:any){
    var url=environment.baseURLNew+'api/Login/reset_password';
    let res=this.http.post(url,payload,{headers:headers});
    return res;
  }
  getpassworddetailsbyId(Id:any){
    var url=environment.baseURLNew+'api/User/get_password_details_by_Id?Id='+Id;
    let res=this.http.get(url,{headers:headers});
    return res;
  }
}
