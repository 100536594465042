import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CountrydialogueComponent } from '../countrydialogue/countrydialogue.component';
import { CountryeditdialogueComponent } from '../countryeditdialogue/countryeditdialogue.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../Services/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DictionaryService } from '../Services/dictionary.service';
import { MatTableDataSource } from '@angular/material/table';
import { Country } from '../Models/User';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css'],
})
export class CountryComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'name', 
    'actions',
  ];

  pageSizeOptions = [5, 10, 25];
  dataSource: any;
  pageEvent: PageEvent = new PageEvent();
  pageSize: number = 10;
  pageIndex: number = 0;
  country: any;
  handlePageEvent(e: PageEvent): void {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageSize + ' ' + this.pageIndex);
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(public dialog: MatDialog,private toastr: ToastrService,
    private _snackBar: MatSnackBar,
    public dictionaryService: DictionaryService,
    private router: Router,
    private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.getGrid();


  }
  dialogRef: MatDialogRef<CountrydialogueComponent> | null = null;
 
  getGrid() {
    this.spinner.show();
    this.dictionaryService.getallcountry().subscribe(
      (resp: any) => {
        if (resp.success == true) {
          this.spinner.hide();
          console.log('Response from server:', resp.result);
          this.country = resp.result;
          console.log('Filtered User List:', this.country); 
          this.dataSource = new MatTableDataSource<Country>(this.country);
          console.log('Data source:', this.dataSource); 
          this.dataSource.paginator = this.paginator;
 
        }
      },
      (err) => {
        this.toastr.error('Error', 'Error');
      }
    );
  }
 
 
 
 
  openDialog(): void {
    const dialogRef = this.dialog.open(CountrydialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
  editCountry(Id:any): void {
    const dialogRef = this.dialog.open(CountryeditdialogueComponent, {
      id: 'custom-overlay',
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-dialog-backdrop',
      data: {                                  
        person: {
          Cid: Id                            
        }
    
    }});
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.dialogRef = null;
    });
  }
}
