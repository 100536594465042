import { Component, Inject, OnInit } from '@angular/core';
import { EdituserlistdialogueComponent } from '../edituserlistdialogue/edituserlistdialogue.component';
import { DictionaryService } from '../Services/dictionary.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Country, Province } from '../Models/User';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  userData:any;  
  editForm: FormGroup;
  selectedHCWId: any;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private dictionaryService: DictionaryService,
    private userService: UserService) {
 
    this.editForm = new FormGroup({
      userName: new FormControl(''),
      phoneNumber:new FormControl(''),
      country:new FormControl(''),
      province:new FormControl(''),
      district:new FormControl(''),
      tehsil:new FormControl(''),
      uc:new FormControl(''),
      facility:new FormControl(''),
      village:new FormControl(''),
      hcwId:new FormControl('')
     });

  }
  

  ngOnInit(): void {
    // this.getallprivilege();
    
 
  const userId = this.route.snapshot.paramMap.get('id');
  if (userId) {
    this.getuserbyId(userId);
  }  
  this.getallcountry();

}
  
  user_name:any;
  password:any;
  privileges: any[] = [];
  countries: Country[]=[];
  provinces: Province[]=[];
  districts:any[]=[];
  tehsils: any[]=[];
  selectedPrivilege: any;
  selectedCountry:any;
  selectedProvince:any;
  selectedDistrict:any;
  selectedTehsil:any;
  selectedUC:any;
  selectedVillage: any;
  selectedFacility:any;
  showDropdown: boolean = false;
  ucs: any[]=[];
  villages:any[]=[];
  facility:any[]=[];
  userId: any;
 
 
  goBack() {
    this.router.navigate(['/homepage/usermanagement']);
  }
 
 
  
     
//  getallprivilege(){
//   this.dictionaryService.getallprivilege().subscribe(
//     (resp:any)=>{
//       this.privileges = resp.result;

//   },
//   (err:any)=>{
//     this.toastr.error('Error', 'Error');

//   }
//   )
//  }
 getallcountry(){
  this.dictionaryService.getallcountry().subscribe(
    (resp:any)=>{
      this.countries = resp.result.filter((country: Country) => country.name === 'Pakistan');
      
      console.log('countries',this.countries);
    },
    (err:any)=>{
      this.toastr.error('Error','Error');
    }
  )
 }

 onCountryChange(event: any) {
  const selectedValue = (event.target as HTMLSelectElement).value;
  this.selectedCountry = selectedValue;

  if (selectedValue) {
    this.getallprovince(selectedValue);
  }  
}


getallprovince(countryId:any){
  this.dictionaryService.getallprovince(countryId).subscribe(
    (resp:any)=>{
      this.provinces = resp.result.filter((province: Province) => province.name === 'Gilgit-Baltistan');
      
    },
    (err:any)=>{
      this.toastr.error('Error','Error')
    }
  )
}
onProvinceChange(event: any) {
  const selectedValueProvince = (event.target as HTMLSelectElement).value;
  this.editForm.controls['province'].setValue(selectedValueProvince);
  this.selectedProvince = selectedValueProvince;

  if (selectedValueProvince) {
    this.getalldistrict(selectedValueProvince);
  }
}


getalldistrict(provinceId:any){
  this.dictionaryService.getalldistrict(provinceId).subscribe(
    (resp:any)=>{
      // this.districts=resp.result;
      this.districts = resp.result.filter((district: any) => 
        district.id !== this.userData.districtId
      );

    },
    (err:any)=>{
      this.toastr.error('Error','Error');
    }
  )
}
onDistrictChange(event:any){
  const selectedValueDistrict = (event.target as HTMLSelectElement).value;
  this.selectedDistrict=selectedValueDistrict;
   // Reset dependent fields
   this.tehsils=[];
    this.editForm.controls.tehsil.setValue(null);
   this.editForm.controls.uc.setValue(null);
   this.editForm.controls.village.setValue(null);
   this.editForm.controls.facility.setValue(null); 
    this.getalltehsil(selectedValueDistrict);


}



getalltehsil(districtId:any){
  this.dictionaryService.getalltehsil(districtId).subscribe(
    (resp:any)=>{
      // this.tehsils=resp.result;
    this.tehsils = resp.result.filter((tehsil: any) => 
    tehsil.id !== this.userData.tehsilId
    );


    },
    (err:any)=>{
      this.toastr.error('Error','Error');
    }
  )
}
onTehsilChange(event:any){
  const selectedValueTehsil = (event.target as HTMLSelectElement).value;
  this.selectedTehsil=selectedValueTehsil; 
  this.ucs=[];
 this.editForm.controls.uc.setValue(null);
 this.editForm.controls.village.setValue(null);
 this.editForm.controls.facility.setValue(null); 

    this.getalluc(selectedValueTehsil);
 
  
}


getalluc(tehsilId:any){
  this.dictionaryService.getalluc(tehsilId).subscribe(
    (resp:any)=>{
      // this.ucs=resp.result;
      this.ucs = resp.result.filter((uc: any) => 
        uc.id !== this.userData.ucId
      );

    },
    (err:any)=>{
      this.toastr.error('Error')
    }
  )
}

onUCChange(event:any){
  const selectedValueUC = (event.target as HTMLSelectElement).value;
  this.selectedUC=selectedValueUC;
  this.villages = [];
  this.facility=[];
 this.editForm.controls.village.setValue(null);
 this.editForm.controls.facility.setValue(null); 
 
    this.getallvillage(selectedValueUC);
    this.getallfacility(selectedValueUC);
 
 
}
getallvillage(ucId:any){
  this.dictionaryService.getallvillage(ucId).subscribe(
    (resp:any)=>{
      // this.villages=resp.result;
      this.villages = resp.result.filter((village: any) => 
        village.id !== this.userData.villageId
      );

    }
  )
}

onVillageChange(event:any){
  const selectedValueVillage = (event.target as HTMLSelectElement).value;
  this.selectedVillage=selectedValueVillage; 
  this.facility=[];
 this.editForm.controls.facility.setValue(null);  
    this.getallfacility(this.selectedUC);
 
 
}
getallfacility(ucId:any){
  this.dictionaryService.getallfacility(ucId).subscribe(
    (resp:any)=>{
      // this.facility=resp.result;
      this.facility = resp.result.filter((facility: any) => 
        facility.id !== this.userData.facilityId
      );

    }
  )
}
CreatePostObjNew(form: any): any {
  var P = this.editForm.value;
  console.log(form);
  const metadata = { 
    uc_id: P.uc,
    hcw_id: P.hcwId,
    full_name: P.userName,
    tehsil_id: P.tehsil,
    country_id: P.country,
    village_id: P.village,
    district_id: P.district,
    facility_id: P.facility,
    province_id: P.province,
    phone_number:P.phoneNumber,
    server_number:0,
 
  }   
  const metadataJson = JSON.stringify(metadata);
  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    username: ''+ P.userName,
    addedBy: 0,
    countryId: P.country,
    provinceId: P.province,
    districtId: P.district,
    tehsilId:P.tehsil,
    ucId: P.uc,
    facilityId: P.facility,
    villageId: P.village,
    metadata: metadataJson,
    id:this.userData.id,
    hcW_Id: ''+P.hcwId,
    fullName: P.userName,
    phoneNumber: ''+P.phoneNumber
  };
  return pp;
}
 
onSubmit(form: FormGroup): void {
 
  var data = this.CreatePostObjNew(form);
  this.userService.EditUser(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok' );
        this.userId=resp.result;
        this.router.navigate(['/homepage/usermanagement']);

      } else {
        this.toastr.success(resp.message, 'Ok' );
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data', 'OK'
      );
    }
  );

   
}
getuserbyId(id: any) {
  this.userService.GetUserByPrimaryId(id).subscribe(
    (response: any) => {
      if (response.success) {
        this.userData = response.result[0];
        console.log(this.userData);

        // Initialize form with IDs and fetch necessary data for dropdowns
        this.editForm.patchValue({
          userName: this.userData.userName,
          phoneNumber: this.userData.phoneNumber,
          country: this.userData.countryId,
          province: this.userData.provinceId,
          district: this.userData.districtId,
          tehsil: this.userData.tehsilId,
          uc: this.userData.ucId,
          village: this.userData.villageId,
          facility: this.userData.facilityId,
          hcwId: this.userData.hcwId
        });
         this.getalldistrict(this.userData.provinceId);
        this.getalltehsil(this.userData.districtId);
        this.getalluc(this.userData.tehsilId);
        this.getallvillage(this.userData.ucId);
        this.getallfacility(this.userData.ucId);
 
      }
    },
    (err: any) => {
      console.log(err);
    }
  );
}
}
