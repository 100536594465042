<div class="outer-container">
    <div
      class="center-container"
      style="height: 110vh; width: 35vw; padding: 10px;padding-top: 30px;"
    >
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >
          <h4>Edit Villages</h4>
        </div>
        <div>
          <form style="width: 28vw;" [formGroup]="editVillageForm">
            <div style="display: flex; justify-content: space-between; gap: 3px">

            <div class="form-group">
              <label for="exampleFormControlSelect1">Country</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              style="width: 213px"
              formControlName="selectedCountry"
              [value]="villageRecord?.country"
              name="country" readonly/>
            </div>
  
            <div class="form-group">
              <label for="exampleInput1">Local Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter Local Code"
                formControlName="local_Code"
                [value]="villageRecord?.localCode"
              />
            </div></div>
            <div style="display: flex; justify-content: space-between">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Province</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              style="width: 213px"
              formControlName="selectedProvince"
              [value]="villageRecord?.province"
              name="province" readonly/>
            </div>
            <div class="form-group">
                <label for="exampleFormControlSelect1">District</label>
                <select
                class="form-control form-control-lg"
                id="exampleFormControlSelect1"
                style="width: 213px"
                (change)="onDistrictChange($event)"
                formControlName="selectedDistrict"
              >
              <option [value]="villageRecord?.districtId">{{ villageRecord?.district }}</option>
              <option *ngFor="let district of districts" [value]="district.id">
                {{ district.name }}
              </option>
              </select>
              </div></div>
            <div style="display: flex; gap: 5px">
            <div class="form-group">
              <label for="exampleInput1">Sub District</label>
              <select
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              style="width: 213px"
              (change)="onSubDistrictChange($event)"
              formControlName="selectedSubDistrict"
            >
            <option [value]="villageRecord?.tehsilId">{{ villageRecord?.tehsil }}</option>
            <option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.id">
              {{ subdistrict.name }}
            </option>
            </select>
            </div>
                <div class="form-group">
                    <label for="exampleInput1">union Council</label>
                    <select
                    class="form-control form-control-lg"
                    id="exampleFormControlSelect1"
                    (change)="onUCChange($event)"
                    formControlName="selectedUC"
                    style="width: 213px"
                  >
                  <option [value]="villageRecord?.ucId">{{ villageRecord?.uc }}</option>
                  <option *ngFor="let uc of unioncouncil" [value]="uc.id">
                    {{ uc.name }}
                  </option>
                  </select>
                  </div></div>
                  <!-- <div style="display: flex; justify-content: space-between">
                  <div class="form-group">
                    <label for="exampleInput1">Facility</label>
                    <select
                    class="form-control form-control-lg"
                    id="exampleFormControlSelect1"
                    (change)="onFacilityChange($event)"
                    formControlName="selectedFacility"
                    style="width: 213px"

                  >
                  <option [value]="villageRecord?.facilityId">{{ villageRecord?.facility }}</option>
                  <option *ngFor="let facility of facility" [value]="facility.id">
                    {{ facility.name }}
                  </option>
                  </select>
                  </div> -->
                  <div class="form-group">
                    <label for="exampleInput1">Village</label>
                    <input
                    type="text"
                    class="form-control form-control-lg"
                    id="subDistrictForm"
                    placeholder="Enter New Facility"
                    formControlName="village_name"
                    name="village"
                  />
                  </div> 
         
          
            <div style="display: flex; justify-content: space-between">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  formControlName="hayatChecked"
                  (change)="oncheckhayat($event)"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hayat
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  formControlName="casiChecked"
                  (change)="oncheckcasi($event)"
                />
                <label class="form-check-label" for="defaultCheck1"> CASI </label>
              </div>
            </div>
          </form>
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close   (click)="onSubmit(editVillageForm)" >Add</button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
 
  