<div class="outer-container">
    <div
      class="center-container"
      style="height: 80vh; width: 35vw; padding: 10px"
    >
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >
          <h4>Edit Terminology</h4>
        </div>
        <div>
          <form [formGroup]="editTermForm">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Group</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="selectedGroup"
              [value]="termRecord?.group"
              name="group"/>
            </div>
  
            <div class="form-group">
              <label for="exampleInput1">Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="code1"
                placeholder="Enter Code"
                formControlName="selectedCode"
                [value]="termRecord?.code"
                name="code"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Name(en)</label>
              <input
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              formControlName="selected_name_en"
              [value]="termRecord?.name_en"
              name="name_en" />
            </div>
            <div class="form-group">
              <label for="exampleInput1">Name (ur)</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="name_ur"
                placeholder="Enter New Name Ur"
                formControlName="selected_name_ur"
                [value]="termRecord?.name_ur"
                name="name_ur"
              />
            </div>
           
          </form>
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close  (click)="onSubmit(editTermForm)" >Edit </button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
  </div>
  