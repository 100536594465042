<div id="modal-content-wrapper">
  <header id="modal-header">
    <h1 id="modal-title">Project List</h1>
  </header>
  <ul>
    <li>FTP Program Application</li>
    <li>Image to Text Converter Application</li>
    <li>Library Management System</li>
    <li>Image fetching API</li>
    <li>Quiz Game Application</li>
  </ul>
  <footer id="modal-footer">
    <button mat-raised-button id="modal-close-button" (click)="closeModal()">
      Close
    </button>
  </footer>
</div>
