import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../header/header/header.module';
import { SidebarModule } from '../sidebar/sidebar.module';



@NgModule({
  declarations: [
    HomeDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule ,
    HeaderModule,
    SidebarModule

  ],
  exports: [
   HomeDashboardComponent
  ]
})
export class DashboardModule { }
