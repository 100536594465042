<div style="display: flex; justify-content: space-between; align-items: center">
  <div class="input-group mb-3 mt-4" style="width: 330px">
    <span class="input-group-text" id="basic-addon1">
      <i class="fa fa-search"></i>
    </span>
    <input
      type="text"
      class="form-control"
      placeholder="Search"
      aria-label="Search"
      aria-describedby="basic-addon1"
    />
  </div>
  <div>
    <button class="circle-button" (click)="openDialog()">+</button>
  </div>
</div>
<table
mat-table
[dataSource]="dataSource"
matSort
matSortActive="date"
matSortDirection="desc"
class="table"
style="width: 70vw; margin-top: 40px; border: 1px solid #d8dbe0;"
>
<!-- ID Column -->
<ng-container matColumnDef="id">
  <th mat-header-cell *matHeaderCellDef>
    S.No
  </th>
  <td mat-cell *matCellDef="let element; let i = index" style="font-size: 12px;">
    {{ pageSize * pageIndex + i + 1 }}
  </td>
</ng-container>

 
<ng-container matColumnDef="countryname">
  <th mat-header-cell *matHeaderCellDef>
    Country
  </th>
  <td mat-cell *matCellDef="let element" style="font-size: 12px;">
    {{ element.country }}
  </td>
</ng-container>
<ng-container matColumnDef="provincename">
  <th mat-header-cell *matHeaderCellDef>
  Province
  </th>
  <td mat-cell *matCellDef="let element" style="font-size: 12px;">
    {{ element.province }}
  </td>
</ng-container>
<ng-container matColumnDef="districtname">
  <th mat-header-cell *matHeaderCellDef>
    District
  </th>
  <td mat-cell *matCellDef="let element" style="font-size: 12px;">
    {{ element.district }}
  </td>
</ng-container>

<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef>
    Sub District
  </th>
  <td mat-cell *matCellDef="let element" style="font-size: 12px;">
    {{ element.name }}
  </td>
</ng-container>

 

<!-- Actions Column -->
<ng-container matColumnDef="actions">
  <th mat-header-cell *matHeaderCellDef style="font-size: 13px;">
    Actions
  </th>
  <td mat-cell *matCellDef="let element" style="display: flex; gap: 8px;">
    <button class="btn" style="background-color: green; color: white;" (click)="editDialog(element.id)">
      Edit
    </button>
    <button class="btn" style="background-color: red; color: white;" (click)="disableSubDistrict(element.id)" >
      Delete
    </button>
  </td>
</ng-container>

<!-- Table Rows -->
<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<!-- Paginator -->
<mat-paginator
[pageSizeOptions]="[8]"
showFirstLastButtons
aria-label="Select Page No."
style="margin-bottom: 20px;"
>
</mat-paginator>
