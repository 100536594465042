import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { District } from '../Models/Dictionary';

@Component({
  selector: 'app-subdistricteditdialogue',
  templateUrl: './subdistricteditdialogue.component.html',
  styleUrls: ['./subdistricteditdialogue.component.css']
})
export class SubdistricteditdialogueComponent implements OnInit {

  editSubDistrictForm: FormGroup;
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: District[]=[];
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
  sub_District_Name:any;
  sub_District_Record: any;
  sub_District_Id: any;
  selectedDistrict: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService) { 
      this.editSubDistrictForm = new FormGroup({
        selectedCountry: new FormControl(''),
        seletedProvince: new FormControl(''),
        selectedDistrict:new FormControl(''),
        local_Code: new FormControl(''),
        sub_District_Name: new FormControl(''),
        hayatChecked:new FormControl(''),
        casiChecked:new FormControl('')

      });



    }

  ngOnInit(): void {
    this.GetSubDistrictRecord();
    this.sub_District_Id = this.data.person.Cid;
    console.log(this.sub_District_Id)
    if (this.sub_District_Record) {
      this.getalldistrict(this.sub_District_Record.provinceId);
    }   }

 

 
    getalldistrict(provinceId:any){
      this.dictionaryService.getalldistrict(provinceId).subscribe(
        (resp:any)=>{
          this.districts=resp.result;
          this.districts = resp.result.filter((district: any) => 
            district.id !== this.sub_District_Record.districtId
          );
    
        },
        (err:any)=>{
          this.toastr.error('Error','Error');
        }
      )
    }
    onDistrictChange(event:any){
      const selectedValueDistrict = (event.target as HTMLSelectElement).value;
      this.selectedDistrict=selectedValueDistrict;
  
    
    }
 
oncheckcasi(event:any){
  if (event.target.checked) {
    console.log('casi is checked');
    this.selectedCasi = true;
  } else {
    console.log('Casi is not checked');
    this.selectedCasi=false;
  }
}

oncheckhayat(event:any){
  if (event.target.checked) {
    console.log('hayat is checked');
    this.selectedHayat = true;
  } else {
    console.log('hayat is not checked');
    this.selectedHayat=false;
  }
}

GetSubDistrictRecord() {
  this.dictionaryService.GetTehsilByPrimaryId(this.data.person.Cid).subscribe(
    (response: any) => {
      if(response.success == true){
        this.sub_District_Record = response.result[0];
        this.editSubDistrictForm.patchValue({
          selectedCountry:this.sub_District_Record?.country,
          selectedProvince:this.sub_District_Record?.province,
          selectedDistrict:this.sub_District_Record.districtId,
          sub_District_Name: this.sub_District_Record?.name,    
          casiChecked:this.sub_District_Record.casi,
          hayatChecked: this.sub_District_Record.hayat,
          local_Code:this.sub_District_Record.localCode

               
         });  
         this.getalldistrict(this.sub_District_Record.provinceId);

      }       
    },
    (err: any) => {
      console.log(err);
    }
  )
}


CreatePostObjNew(form: any): any {
  var P = this.editSubDistrictForm.value;
  console.log(form);
  const metadata = {
    casi: P.selectedCasi,
    hayat: P.selectedHayat,
    local_Code:P.local_Code,
   };
   const metadataJson = JSON.stringify(metadata);
  //  const name={
  //    en:P.district_name,
  //    ur:P.district_name
  //  };
  //  const nameJson=JSON.stringify(name);
  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    id:this.sub_District_Record.id,
    districtId:P.selectedDistrict,
    name: P.sub_District_Name,
    metadata: metadataJson,
    name_Check: P.name,
    casi:P.casiChecked,
    hayat:P.hayatChecked,
    local_Code:P.local_Code
    
 
    
   };
  return pp;
  console.log(pp);
}
 
  onSubmit(editSubDistrictForm: FormGroup) :void{
    var data = this.CreatePostObjNew(editSubDistrictForm);
   this.dictionaryService.edittehsil(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok');
         this.router.navigate(['/tehsil']);
      } else {
        this.toastr.error('failed to insert data');
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data');
    }
  );
}  
}
