import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Tehsil } from '../Models/Dictionary';
@Component({
  selector: 'app-facilityeditdialogue',
  templateUrl: './facilityeditdialogue.component.html',
  styleUrls: ['./facilityeditdialogue.component.css']
})
export class FacilityeditdialogueComponent implements OnInit {

  editFacilityForm: FormGroup;
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: any[] = [];
  subdistricts: Tehsil[]=[];
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
  selectedDistrict: any;
  selectedSubDistrict:any;
   district_name:any;
  facilityRecord: any;
  facilityId: any;
  unioncouncil: any;
  selectedUC: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService) { 
      this.editFacilityForm = new FormGroup({
        selectedCountry: new FormControl(''),
        selectedProvince: new FormControl(''),
        selectedDistrict: new FormControl(''),
        selectedSubDistrict:new FormControl(''),
        selectedUC:new FormControl(''),
        facility_name: new FormControl(''),
        local_Code: new FormControl(''),
        hayatChecked:new FormControl(''),
        casiChecked:new FormControl('')

      });



    }

  ngOnInit(): void {
    this.GetFacilityRecord();
    this.facilityId = this.data.person.Cid;
    console.log(this.facilityId)
   }


   getalldistrict(provinceId:any){
    this.dictionaryService.getalldistrict(provinceId).subscribe(
      (resp:any)=>{
        this.districts=resp.result;
        this.districts = resp.result.filter((district: any) => 
          district.id !== this.facilityRecord.districtId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onDistrictChange(event:any){
    const selectedValueDistrict = (event.target as HTMLSelectElement).value;
    this.selectedDistrict=selectedValueDistrict;
    if(selectedValueDistrict){
      this.getallsubdistrict(selectedValueDistrict);
     }
  
  }


  getallsubdistrict(districtId:any){
    this.dictionaryService.getalltehsil(districtId).subscribe(
      (resp:any)=>{
        this.subdistricts=resp.result;
        this.subdistricts = resp.result.filter((subdistrict: any) => 
          subdistrict.id !== this.facilityRecord.districtId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onSubDistrictChange(event:any){
    const selectedValueSubDistrict = (event.target as HTMLSelectElement).value;
    this.selectedSubDistrict=selectedValueSubDistrict;
    if(selectedValueSubDistrict){
      this.getalluc(selectedValueSubDistrict);
     }
  
  }

  getalluc(tehsilId:any){
    this.dictionaryService.getalluc(tehsilId).subscribe(
      (resp:any)=>{
        this.unioncouncil=resp.result;
        this.unioncouncil = resp.result.filter((uc: any) => 
          uc.id !== this.facilityRecord.tehsilId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onUCChange(event:any){
    const selectedValueUC = (event.target as HTMLSelectElement).value;
    this.selectedUC=selectedValueUC;

  
  }





oncheckcasi(event:any){
  if (event.target.checked) {
    console.log('casi is checked');
    this.selectedCasi = true;
  } else {
    console.log('Casi is not checked');
    this.selectedCasi=false;
  }
}

oncheckhayat(event:any){
  if (event.target.checked) {
    console.log('hayat is checked');
    this.selectedHayat = true;
  } else {
    console.log('hayat is not checked');
    this.selectedHayat=false;
  }
}
GetFacilityRecord() {
  this.dictionaryService.GetFacilityByPrimaryId(this.data.person.Cid).subscribe(
    (response: any) => {
      if(response.success == true){
        this.facilityRecord = response.result[0];
        this.editFacilityForm.patchValue({
          selectedCountry:this.facilityRecord?.country,
          selectedProvince:this.facilityRecord?.province,
          selectedDistrict: this.facilityRecord?.districtId,
          selectedSubDistrict:this.facilityRecord?.tehsilId,
          selectedUC:this.facilityRecord?.ucId,
          facility_name:this.facilityRecord.name,    
          casiChecked:this.facilityRecord.casi,
          hayatChecked: this.facilityRecord.hayat,
          local_Code:this.facilityRecord.localCode

               
         });
         this.getalldistrict(this.facilityRecord.provinceId);
         this.getallsubdistrict(this.facilityRecord.districtId);
         this.getalluc(this.facilityRecord.tehsilId);
      }       
    },
    (err: any) => {
      console.log(err);
    }
  )
}


CreatePostObjNew(form: any): any {
  var P = this.editFacilityForm.value;
  console.log(form);
  const metadata = {
    casi: P.selectedCasi,
    hayat: P.selectedHayat,
    local_code:P.local_Code,
   };
   const metadataJson = JSON.stringify(metadata);
  //  const name={
  //    en:P.district_name,
  //    ur:P.district_name
  //  };
  //  const nameJson=JSON.stringify(name);
  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    id:this.facilityRecord.id,
    districtId:P.selectedDistrict,
    tehsilId:P.selectedTehsil,
    ucId:P.selectedFacility,
    name: P.facility_name,
    metadata: metadataJson,
    name_Check: P.name,
    casi:P.casiChecked,
    hayat:P.hayatChecked,
    localCode:P.local_Code
    
 
    
   };
  return pp;
  console.log(pp);
}
 
  onSubmit(editFacilityForm: FormGroup) :void{
    var data = this.CreatePostObjNew(editFacilityForm);
   this.dictionaryService.editfacility(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok');
         this.router.navigate(['/facility']);
      } else {
        this.toastr.error('failed to insert data');
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data');
    }
  );
}  
}
