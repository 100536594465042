// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import { HttpHeaders } from "@angular/common/http";

export const environment = {
  production: false,
  
       //baseURLNew: 'https://localhost:44390/',
       baseURLNew:'https://smk.gb.casi.hayat.akdndhrc.org/~api-gb/'
     //baseURLNew: 'http://10.222.113.19:84/', //EMR_Prod,
};

export const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('token')}`
});
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */ 
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
