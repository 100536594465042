<div class="outer-container">
  <div
    class="center-container"
    style="height: 110vh; width: 35vw; padding: 10px; padding-top: 30px"
  >
    <mat-card>
      <div
        style="
          display: flex;
          justify-content: center;
          text-decoration: underline;
        "
      >
        <h4>Add Villages</h4>
      </div>
      <div>
        <form style="width: 28vw">
          <div style="display: flex; justify-content: space-between; gap: 3px">
           <div class="form-group">
            <label for="exampleFormControlSelect1">Country</label>
            <select
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              style="width: 213px"
              [(ngModel)]="selectedCountry"
              (change)="onCountryChange($event)">
                <option [ngValue]="undefined" [disabled]="true">
                  Select country
                </option>
                <option *ngFor="let country of countries" [value]="country.id">
                  {{ country.name }}
                </option></select>
          </div>


          <div class="form-group">
            <label for="exampleInput1">Local Code</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="localCode"
              placeholder="Enter Local Code"
              [(ngModel)]="local_Code"
              name="localCode"
            />
          </div>
          </div>
          <div style="display: flex; justify-content: space-between">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Province</label>
            <select
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              style="width: 213px"
              [(ngModel)]="selectedProvince"
              (change)="onProvinceChange($event)"
            
            >
            <option [ngValue]="undefined" [disabled]="true">
              Select Province
            </option>
            <option *ngFor="let province of provinces" [value]="province.id">
              {{ province.name }}
            </option>
            </select>
          </div> 
          <div class="form-group">
            <label for="exampleFormControlSelect1">District</label>
            <select
              class="form-control form-control-lg"
              id="exampleFormControlSelect1"
              [(ngModel)]="selectedDistrict"
              style="width: 213px"
              (change)="onDistrictChange($event)"  >
              <option [ngValue]="undefined" [disabled]="true">
                Select District
              </option>
              <option
              *ngFor="let district of districts" [value]="district.id"
              >
                {{ district.name }}
              </option>
            </select>
          </div></div>
          <div style="display: flex; gap: 5px">
          <div class="form-group">
            <label for="exampleInput1">Sub District</label>
            <select
            class="form-control form-control-lg"
            id="hayatForm"
            style="width: 213px"
            [(ngModel)]="selectedSubDistrict"
            (change)="onSubDistrictChange($event)"    
          >
            <option [ngValue]="undefined" [disabled]="true">
              Select SubDistrict
            </option>
            <option
              *ngFor="let subdistrict of subdistricts"
              [value]="subdistrict.id"
            >
              {{ subdistrict.name }}
            </option>
          </select>
          </div>
            <div class="form-group">
              <label for="exampleInput1">Union Council</label>
              <select
              class="form-control form-control-lg"
              id="hayatForm1"
              style="width: 213px"
              [(ngModel)]="selectedUC"
              (change)="onUCChange($event)"    
      
              >
              <option [ngValue]="undefined" [disabled]="true">
                Select UC
              </option>
              <option
                *ngFor="let uc of unioncouncil"
                [value]="uc.id">
                {{ uc.name }}
              </option>  
            </select>
            </div></div>
            <!-- <div style="display: flex; gap: 5px">
              <div class="form-group">
              <label for="exampleInput1">Facility</label> -->
              <!-- <select
              class="form-control form-control-lg"
              id="hayatForm"
              style="width: 213px"
              [(ngModel)]="selectedFacility"
              (change)="onFacilityChange($event)"    
              name="facility"
              >
              <option [ngValue]="undefined" [disabled]="true">
                Select Facility
              </option>
              <option
                *ngFor="let facility of facility"
                [value]="facility.id">
                {{ facility.name }}
              </option>  
            </select>
            </div> -->
            <div class="form-group">
              <label for="subDistrictForm">Village</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="subDistrictForm"
                placeholder="Enter New Village"
                [(ngModel)]="village_name"
                name="village"
              />
            </div>
     

          <div style="display: flex; justify-content: space-between">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                [(ngModel)]="selectedHayat"
                (change)="oncheckhayat($event)"
              />
              <label class="form-check-label" for="defaultCheck1">
                Hayat
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                [(ngModel)]="selectedCasi"
                (change)="oncheckcasi($event)"
              />
              <label class="form-check-label" for="defaultCheck1"> CASI </label>
            </div>
          </div>
        </form>
        <mat-dialog-actions>
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          <button mat-button mat-dialog-close (click)="Submit()">Add</button>
        </mat-dialog-actions>
      </div>
    </mat-card>
  </div>
</div>
