import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-districteditdialogue',
  templateUrl: './districteditdialogue.component.html',
  styleUrls: ['./districteditdialogue.component.css']
})
export class DistricteditdialogueComponent implements OnInit {
  
  editDistrictForm: FormGroup;
  countries: Country[] = [];
  provinces: Province[] = [];
 
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
   district_name:any;
  districtRecord: any;
  districtId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService) { 
      this.editDistrictForm = new FormGroup({
        selectedCountry: new FormControl(''),
        seletedProvince: new FormControl(''),
        local_Code: new FormControl(''),
        district_name: new FormControl(''),
        hayatChecked:new FormControl(''),
        casiChecked:new FormControl('')

      });



    }

  ngOnInit(): void {
    this.GetDistrictRecord();
    this.districtId = this.data.person.Cid;
    console.log(this.districtId)
   }

 

 
 
  
 
oncheckcasi(event:any){
  if (event.target.checked) {
    console.log('casi is checked');
    this.selectedCasi = true;
  } else {
    console.log('Casi is not checked');
    this.selectedCasi=false;
  }
}

oncheckhayat(event:any){
  if (event.target.checked) {
    console.log('hayat is checked');
    this.selectedHayat = true;
  } else {
    console.log('hayat is not checked');
    this.selectedHayat=false;
  }
}
GetDistrictRecord() {
  this.dictionaryService.GetDistrictByPrimaryId(this.data.person.Cid).subscribe(
    (response: any) => {
      if(response.success == true){
        this.districtRecord = response.result[0];
        this.editDistrictForm.patchValue({
          selectedCountry:this.districtRecord?.country,
          selectedProvince:this.districtRecord?.province,
          district_name: this.districtRecord.name,    
          casiChecked:this.districtRecord.casi,
          hayatChecked: this.districtRecord.hayat,
          local_Code:this.districtRecord.localCode

               
         });

      }       
    },
    (err: any) => {
      console.log(err);
    }
  )
}


CreatePostObjNew(form: any): any {
  var P = this.editDistrictForm.value;
  console.log(form);
  const metadata = {
    casi: P.selectedCasi,
    hayat: P.selectedHayat,
    local_code:P.local_Code,
   };
   const metadataJson = JSON.stringify(metadata);
  //  const name={
  //    en:P.district_name,
  //    ur:P.district_name
  //  };
  //  const nameJson=JSON.stringify(name);
  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    id:this.districtRecord.id,
    name: P.district_name,
    metadata: metadataJson,
    name_Check: P.name,
    casi:P.casiChecked,
    hayat:P.hayatChecked,
    local_Code:P.local_Code
    
 
    
   };
  return pp;
  console.log(pp);
}
 
  onSubmit(editDistrictForm: FormGroup) :void{
    var data = this.CreatePostObjNew(editDistrictForm);
   this.dictionaryService.editdistrict(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok');
         this.router.navigate(['/district']);
      } else {
        this.toastr.error('failed to insert data');
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data');
    }
  );
}  
  

}
