<div class="outer-container">
    <div class="center-container" style="height: 70vh">
      <mat-card>
        <div
          style="
            display: flex;
            justify-content: center;
            text-decoration: underline;
          "
        >
          <h4>Edit Province</h4>
        </div>
        <div>
          <form>
            <div class="form-group">
              <label for="exampleInput1">Country</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="countryInput"
                placeholder="Enter New Country"
              />
            </div>
            <div class="form-group">
              <label for="exampleInput1">Local Code</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="localCode"
                placeholder="Enter Local Code"
              />
            </div>
            <div class="form-group">
                <label for="exampleInput1">Province</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="localCode"
                  placeholder="Enter New Province"
                />
              </div>
            <!-- <label class="form-check-label" for="defaultCheck1">
              For Project
            </label> -->
            <div style="display: flex; justify-content: space-between">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hayat
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"> CASI </label>
              </div>
            </div>
          </form>
  
          <mat-dialog-actions>
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            <button mat-button mat-dialog-close>Edit</button>
          </mat-dialog-actions>
        </div>
      </mat-card>
    </div>
  </div>
  