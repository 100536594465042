import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from '../Services/dictionary.service';
import { UserService } from '../Services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import JSEncrypt from 'jsencrypt';
import { Router } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { ToastrService } from 'ngx-toastr';
import { Country, Privilege, Province } from '../Models/User';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Facility, Tehsil } from '../Models/Dictionary';
@Component({
  selector: 'app-villageeditdialogue',
  templateUrl: './villageeditdialogue.component.html',
  styleUrls: ['./villageeditdialogue.component.css']
})
export class VillageeditdialogueComponent implements OnInit {

  editVillageForm: FormGroup;
  countries: Country[] = [];
  provinces: Province[] = [];
  districts: any[] = [];
  subdistricts: Tehsil[]=[];
  facility:Facility[]=[];
  selectedHayat: boolean = false;
  selectedCasi: boolean = false;
  selectedDistrict: any;
  selectedSubDistrict:any;
  selectedFacility:any;
  village_name:any;
  villageRecord: any;
  unioncouncil: any;
  selectedUC: any;
  villageId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private toastr: ToastrService) { 
      this.editVillageForm = new FormGroup({
        selectedCountry: new FormControl(''),
        selectedProvince: new FormControl(''),
        selectedDistrict: new FormControl(''),
        selectedSubDistrict:new FormControl(''),
        selectedUC:new FormControl(''),
        village_name: new FormControl(''),
        local_Code: new FormControl(''),
        hayatChecked:new FormControl(''),
        casiChecked:new FormControl('')

      });



    }

  ngOnInit(): void {
    this.GetVillageRecord();
    this.villageId = this.data.person.Cid;
    console.log(this.villageId)
   }


   getalldistrict(provinceId:any){
    this.dictionaryService.getalldistrict(provinceId).subscribe(
      (resp:any)=>{
        this.districts=resp.result;
        this.districts = resp.result.filter((district: any) => 
          district.id !== this.villageRecord.districtId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onDistrictChange(event:any){
    const selectedValueDistrict = (event.target as HTMLSelectElement).value;
    this.selectedDistrict=selectedValueDistrict;
    if(selectedValueDistrict){
      this.getallsubdistrict(selectedValueDistrict);
     }
  
  }


  getallsubdistrict(districtId:any){
    this.dictionaryService.getalltehsil(districtId).subscribe(
      (resp:any)=>{
        this.subdistricts=resp.result;
        this.subdistricts = resp.result.filter((subdistrict: any) => 
          subdistrict.id !== this.villageRecord.districtId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onSubDistrictChange(event:any){
    const selectedValueSubDistrict = (event.target as HTMLSelectElement).value;
    this.selectedSubDistrict=selectedValueSubDistrict;
    if(selectedValueSubDistrict){
      this.getalluc(selectedValueSubDistrict);
     }
  
  }

  getalluc(tehsilId:any){
    this.dictionaryService.getalluc(tehsilId).subscribe(
      (resp:any)=>{
        this.unioncouncil=resp.result;
        this.unioncouncil = resp.result.filter((uc: any) => 
          uc.id !== this.villageRecord.tehsilId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onUCChange(event:any){
    const selectedValueUC = (event.target as HTMLSelectElement).value;
    this.selectedUC=selectedValueUC;
    if(selectedValueUC){
      this.getallfacility(selectedValueUC);
     }
  
  }


  getallfacility(ucId:any){
    this.dictionaryService.getallfacility(ucId).subscribe(
      (resp:any)=>{
        this.facility=resp.result;
        this.facility = resp.result.filter((facility: any) => 
          facility.id !== this.villageRecord.ucId
        );
  
      },
      (err:any)=>{
        this.toastr.error('Error','Error');
      }
    )
  }
  onFacilityChange(event:any){
    const selectedValueFacility = (event.target as HTMLSelectElement).value;
    this.selectedFacility=selectedValueFacility;

  
  }


oncheckcasi(event:any){
  if (event.target.checked) {
    console.log('casi is checked');
    this.selectedCasi = true;
  } else {
    console.log('Casi is not checked');
    this.selectedCasi=false;
  }
}

oncheckhayat(event:any){
  if (event.target.checked) {
    console.log('hayat is checked');
    this.selectedHayat = true;
  } else {
    console.log('hayat is not checked');
    this.selectedHayat=false;
  }
}
GetVillageRecord() {
  this.dictionaryService.GetVillageByPrimaryId(this.data.person.Cid).subscribe(
    (response: any) => {
      if(response.success == true){
        this.villageRecord = response.result[0];
        this.editVillageForm.patchValue({
          selectedCountry:this.villageRecord?.country,
          selectedProvince:this.villageRecord?.province,
          selectedDistrict: this.villageRecord?.districtId,
          selectedSubDistrict:this.villageRecord?.tehsilId,
          selectedUC:this.villageRecord?.ucId,
          village_name:this.villageRecord.name,    
          casiChecked:this.villageRecord.casi,
          hayatChecked: this.villageRecord.hayat,
          local_Code:this.villageRecord.localCode

               
         });
         this.getalldistrict(this.villageRecord.provinceId);
         this.getallsubdistrict(this.villageRecord.districtId);
         this.getalluc(this.villageRecord.tehsilId);
   
      }       
    },
    (err: any) => {
      console.log(err);
    }
  )
}


CreatePostObjNew(form: any): any {
  var P = this.editVillageForm.value;
  console.log(form);
  const metadata = {
    casi: P.selectedCasi,
    hayat: P.selectedHayat,
    local_code:P.local_Code,
   };
   const metadataJson = JSON.stringify(metadata);
  //  const name={
  //    en:P.district_name,
  //    ur:P.district_name
  //  };
  //  const nameJson=JSON.stringify(name);
  var pp = {
    score: '' + P.score,
    result: '' + P.result,
    id:this.villageRecord.id,
    districtId:P.selectedDistrict,
    tehsilId:P.selectedSubDistrict,
    ucId:P.selectedUC,
    name: P.village_name,
    metadata: metadataJson,
    name_Check: P.village_name,
    casi:P.casiChecked,
    hayat:P.hayatChecked,
    localCode:P.local_Code
    
 
    
   };
  return pp;
  console.log(pp);
}
 
  onSubmit(editVillageForm: FormGroup) :void{
    var data = this.CreatePostObjNew(editVillageForm);
   this.dictionaryService.editvillage(data).subscribe(
    (resp: any) => {
      if (resp.success) {
        this.toastr.success(resp.message, 'Ok');
         this.router.navigate(['/village']);
      } else {
        this.toastr.error('failed to insert data');
      }
    },
    (error: any) => {
      this.toastr.error('failed to insert data');
    }
  );
}  
}
